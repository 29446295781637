class InductionCompetency {

    cardType = null;
    idNumber = null;
    expiryDate = null;

    frontCardFile = null;
    backCardFile = null;

    frontCardId = null;
    backCardId = null;

    constructor(cardType, idNumber, expiryDate, frontCardUri, backCardUri) {
        this.cardType = cardType;
        this.idNumber = idNumber;
        this.expiryDate = expiryDate;
        this.frontCardFile = frontCardUri;
        this.backCardFile = backCardUri;
    }

}

export default InductionCompetency;