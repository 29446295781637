import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.baseURL = BASE_URL;

export const API = (function(axios) {
    return {
        parse(resp) {
            var out = {
                success : false,
                data : null,
                error : "An unknown error has occurred"
            };

            if (resp != null) {
                out.success = resp.data.success;
                out.error = resp.data.error;
                out.data = resp.data.data;
            }

            return out;
        },
        INDUCTION_CONFIG : {
            INDUCTION_VIDEO_ACTIVE : "induction_video_active",
            COVID_VIDEO_ACTIVE : "covid_video_active",
            QUESTIONNAIRE_ACTIVE : "questionnaire_active"
        }
    }

})(axios);

export const ENDPOINTS = {
    api : {
        getVideo : `${BASE_URL}/api/get_induction_video`,
        submitInductionUser : `${BASE_URL}/api/submit_induction_user`,
        uploadInductionUserImage : `${BASE_URL}/api/upload_induction_user_image`,
        submitInductionUserCompetency : `${BASE_URL}/api/submit_induction_user_competency`,
        uploadInductionUserCompetencyImage : `${BASE_URL}/api/upload_induction_user_competency_image`,
        submitInductionUserQuestionnaireAnswers : `${BASE_URL}/api/submit_induction_user_questionnaire_answers`,
        uploadTemporaryImage : `${BASE_URL}/api/upload_induction_temporary_image/`,
        completeInduction : `${BASE_URL}/api/complete_induction/`,
        getInductionConfig : `${BASE_URL}/api/get_induction_config/`,
        getInductionVideoConfig : `${BASE_URL}/api/get_induction_video_config/`
    }
}

export const INDUCTION_VIDEO_TYPES = {
    introduction : 1,
    COVID : 2
}