import React from "react";
import BaseComponent from "../BaseComponent";
import CommonHeader from "../common/CommonHeader";

import "../../assets/tsl/css/induction-start.css";

class InductionStart extends BaseComponent {

    moveToNextScreen() {
        window.location.href = "/induction/gdpr";
    }

    render() {
        return (
            <div className="container induction-start-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4" />
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="intro-text">
                            {
                                this.getString("inductionIntro.title").split("\n").map((line, i) => (
                                    <p key={i}>{line}</p>
                                ))
                            }
                        </div>
                        <div className="bottom-button">
                            <span className="btn btn-success full-button" onClick={this.moveToNextScreen}>{this.getString("common.continue")}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default InductionStart;