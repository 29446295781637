import React from "react";
import BaseComponent from "../BaseComponent";
import CommonHeader from "../common/CommonHeader";

import "../../assets/tsl/css/induction-signature.css";
import InductionSubmissionManager from "../../forms/InductionSubmissionManager";

class InductionSignature extends BaseComponent {

    static MODE_GDPR = 1;
    static MODE_INDUCTION = 2;
    static MODE_RAMS = 3;

    inductionSubmissionManager = InductionSubmissionManager.getInstance();

    mode = InductionSignature.MODE_GDPR;
    nameKey = null;

    constructor(props, context) {
        super(props, context);

        if (props != null) {
            this.mode = props.mode;
        }

        let title;
        let message;

        if (this.mode === InductionSignature.MODE_GDPR) {
            title = this.getString("inductionSignature.gdprTitle");
            message = this.getString("inductionSignature.gdprMessage");
            this.nameKey = "GDPRName";
        } else if (this.mode === InductionSignature.MODE_INDUCTION) {
            title = this.getString("inductionSignature.inductionTitle");
            message = this.getString("inductionSignature.inductionMessage");
            this.nameKey = "declarationName";
        } else {
            title = this.getString("inductionSignature.ramsTitle");
            message = this.getString("inductionSignature.ramsMessage", {company_name : this.inductionSubmissionManager.base.companyName});
            this.nameKey = "RAMSName";
        }

        this.state = {
            screenTitle : title,
            screenMessage : message,
            signatureName : "",
            signatureAgree : false
        };

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm() {
        if (this.state.signatureName !== "" && this.state.signatureAgree) {
            this.inductionSubmissionManager.base[this.nameKey] = this.state.signatureName;

            InductionSubmissionManager.save();

            if (this.mode === InductionSignature.MODE_GDPR) {
                window.location.href = "/induction/photo/";
            } else if (this.mode === InductionSignature.MODE_INDUCTION) {
                window.location.href = "/induction/rams/";
            } else {
                window.location.href = "/induction/competency/";
            }
        }
    }

    render() {
        let buttonExtraClass = "";
        if ("signatureName" in this.state && (this.state.signatureName === "" || !this.state.signatureAgree)) {
            buttonExtraClass = " disabled";
        }

        return(
            <div className="container induction-signature-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4" />
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="screen-title">
                            {this.state.screenTitle}
                        </div>
                        <div className="message">
                            {this.state.screenMessage.split("\n").map((line, i) => (
                                <p key={i}>{line}</p>
                            ))}
                        </div>
                        <div className="sign-label">
                            {this.getString("inductionSignature.agreement")}
                        </div>
                        <div className="sign-input-container">
                            <div className="text-field">
                                <input type="text" className="form-control" name="signatureName" onChange={this.handleChange} value={this.state.signatureName} />
                            </div>
                            <div className="checkbox">
                                <input type="checkbox" name="signatureAgree" onChange={this.handleChange} checked={this.state.signatureAgree} />
                            </div>
                        </div>

                        <div className="bottom-button">
                            <span className={"btn btn-success full-button" + buttonExtraClass} onClick={this.submitForm}>{this.getString("common.continue")}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InductionSignature;