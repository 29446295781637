import React from "react";
import Axios from "axios";
import moment from "moment";

import BaseComponent from "../BaseComponent";
import InductionSubmissionManager from "../../forms/InductionSubmissionManager";
import CommonHeader from "../common/CommonHeader";

import InductionCompetency from "../../forms/InductionCompetency";
import { API, ENDPOINTS } from "../../networking/API";

import "../../assets/tsl/css/induction-competency.css";

const COMPETENCY_IMAGE_TYPE_FRONT = 1;
const COMPETENCY_IMAGE_TYPE_BACK = 2;

class InductionCompetencyLanding extends BaseComponent {

    inductionSubmissionManager = InductionSubmissionManager.getInstance();

    competencyData = [];

    constructor(props, context) {
        super(props, context);

        let competencyData = [];
        for (let i = 0; i < 4; i++) {
            if (this.inductionSubmissionManager.competency !== undefined) {
                if (this.inductionSubmissionManager.competency.length > i) {
                    competencyData[i] = this.inductionSubmissionManager.competency[i];
                } else {
                    competencyData.push(null);
                }
            } else {
                competencyData.push(null);
            }
        }

        this.initState({
            competencyData : competencyData,
            editorModalOpen : false,
            selectedCompetencyIndex : -1,
            cardType : null,
            idNumber : null,
            expiryDate : null,
            frontOfCard : null,
            backOfCard : null,
            uploadProgress : 0,
            imageNetworkInFlight : false
        });

        this.competencyFrontCard = React.createRef();
        this.competencyBackCard = React.createRef();

        this.launchCompetencyEditor = this.launchCompetencyEditor.bind(this);
        this.closeCompetencyEditor = this.closeCompetencyEditor.bind(this);
        this.handleCompetencyPhotoClick = this.handleCompetencyPhotoClick.bind(this);
        this.handleCompetencyPhotoChange = this.handleCompetencyPhotoChange.bind(this);
        this.validateCompetency = this.validateCompetency.bind(this);
        this.saveCompetencyItemToState = this.saveCompetencyItemToState.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.validateExpiryDate = this.validateExpiryDate.bind(this);
        this.uploadTemporaryImage = this.uploadTemporaryImage.bind(this);
        this.saveAndMoveOn = this.saveAndMoveOn.bind(this);
        this.debugShowUploadProgress = this.debugShowUploadProgress.bind(this);
    }

    launchCompetencyEditor(index) {
        let state = {
            editorModalOpen : true,
            selectedCompetencyIndex : index,
            cardType : "",
            idNumber : "",
            expiryDate : "",
            frontOfCard : null,
            backOfCard : null
        };

        if (this.state.competencyData[index] != null) {
            let data = this.state.competencyData[index];

            let defaultExpiry = moment().format("YYYY-MM-DD");
            if (data.expiryDate != null) {
                defaultExpiry = moment(data.expiryDate, "X").format("YYYY-MM-DD");
            }

            state.cardType = data.cardType;
            state.idNumber = data.idNumber;
            state.expiryDate = defaultExpiry;
            state.frontOfCard = data.frontCardUri;
            state.backOfCard = data.backCardUri;
        }

        this.setState(state);
    }

    closeCompetencyEditor() {
        this.setState({
            editorModalOpen : false
        });
    }

    handleCompetencyPhotoClick(type) {
        if (type === COMPETENCY_IMAGE_TYPE_FRONT) {
            this.competencyFrontCard.current.click();
        } else {
            this.competencyBackCard.current.click();
        }
    }

    handleCompetencyPhotoChange(event, type) {
        if (event.target.files.length > 0) {
            let file = event.target.files[0];

            if (type === COMPETENCY_IMAGE_TYPE_FRONT) {
                this.setState({
                    frontOfCardFile : file
                });
            } else {
                this.setState({
                    backOfCardFile : file
                });
            }

            let reader = new FileReader();
            reader.onload = () => {
                let result = reader.result;
                if (type === COMPETENCY_IMAGE_TYPE_FRONT) {
                    this.setState({
                        frontOfCard : result
                    });
                } else {
                    this.setState({
                        backOfCard : result
                    });
                }
            };
            reader.readAsDataURL(file);
        }
    }

    validateCompetency() {
        let keys = ["cardType", "idNumber", "expiryDate", "frontOfCard", "backOfCard"];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (this.state[key] !== undefined) {
                let value = this.state[key];

                if (value == null || value === "") {
                    return false;
                }
            }
        }

        if (!this.validateExpiryDate()) {
            return false;
        }

        return true;
    }

    validateExpiryDate() {
        if (this.state.expiryDate != null) {
            let expiry = parseInt(moment(this.state.expiryDate).format("X"));
            let now = parseInt(moment().format("X"));

            return expiry > now;
        }
        return false;
    }

    saveCompetencyItemToState() {
        if (!this.validateCompetency()) return;

        if (this.state.selectedCompetencyIndex >= 0) {
            let competency = new InductionCompetency(
                this.state.cardType,
                this.state.idNumber,
                moment(this.state.expiryDate).format("X"),
                this.state.frontOfCardFile,
                this.state.backOfCardFile
            );

            let competencies = this.state.competencyData;
            competencies[this.state.selectedCompetencyIndex] = competency;
            this.setState({
                competencyData : competencies
            });

            this.closeCompetencyEditor();
        }
    }

    validateForm() {
        for (let i = 0; i < this.state.competencyData.length; i++) {
            if (this.state.competencyData[i] != null) {
                return true;
            }
        }
        return false;
    }

    submitForm() {
        if (this.validateForm()) {
            this.competencyData = this.state.competencyData;
            this.uploadTemporaryImage(0, 0);

            this.setState({
                imageNetworkInFlight : true
            });
        }
    }

    uploadTemporaryImage(competencyIndex, imageIndex) {
        if (competencyIndex < this.competencyData.length) {
            let competency = this.competencyData[competencyIndex];

            if (competency != null) {
                let photoFile = null;
                if (imageIndex === 0) {
                    photoFile = competency.frontCardFile;
                } else {
                    photoFile = competency.backCardFile;
                }

                if (photoFile == null) {
                    if (imageIndex === 0) {
                        this.uploadTemporaryImage(competencyIndex, imageIndex + 1);
                    } else {
                        this.uploadTemporaryImage(competencyIndex + 1, 0);
                    }
                    return;
                }

                let formData = new FormData();
                formData.append("image", photoFile);

                Axios.post(ENDPOINTS.api.uploadTemporaryImage, formData, {
                    onUploadProgress: (data) => {
                        if (data.lengthComputable) {
                            var percent = (data.loaded / data.total) * 100;

                            this.setState({
                                uploadProgress: percent
                            });
                        }
                    }
                })
                    .then((r) => {
                        let resp = API.parse(r);
                        if (resp.success) {
                            if (imageIndex === 0) {
                                competency.frontCardId = resp.data.imageId;
                                competency.frontCardFile = null;
                            } else {
                                competency.backCardId = resp.data.imageId;
                                competency.backCardFile = null;
                            }

                            this.competencyData[competencyIndex] = competency;
                        }

                        if (imageIndex === 0) {
                            this.uploadTemporaryImage(competencyIndex, imageIndex + 1);
                        } else {
                            this.uploadTemporaryImage(competencyIndex + 1, 0);
                        }
                    })
                    .catch(console.log);
            } else {
                if (imageIndex === 0) {
                    this.uploadTemporaryImage(competencyIndex, imageIndex + 1);
                } else {
                    this.uploadTemporaryImage(competencyIndex + 1, 0);
                }
            }
        } else {
            this.saveAndMoveOn();
        }
    }

    saveAndMoveOn() {
        this.inductionSubmissionManager.competency = this.state.competencyData;

        InductionSubmissionManager.save();

        window.location.href = "/induction/video-intro/";
    }

    debugShowUploadProgress() {
        this.setState({
            imageNetworkInFlight : true,
            uploadProgress : 50
        });
    }

    render() {
        let modalExtraStyle = "";
        if (this.state.editorModalOpen) {
            modalExtraStyle = " show";
        }

        let modalSubmitExtraStyle = "";
        if (!this.validateCompetency()) {
            modalSubmitExtraStyle = " disabled";
        }


        let mainSubmitExtraStyle = "";
        if (!this.validateForm()) {
            mainSubmitExtraStyle = " disabled";
        }

        let progressElement = [];
        if (this.state.imageNetworkInFlight) {
            progressElement = (
                <div className="blocker">
                    <div className="blocker-content">
                        <div className="progress-area">
                            <div className="progress-title">{this.getString("submission.progress", { percentage : this.state.uploadProgress.toFixed(0) })}</div>
                            <div className="progress-upload">
                                <div className="progress-upload-bar" style={{width : this.state.uploadProgress + "%"}} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="container induction-competency-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4" />
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="screen-title" onClick={this.debugShowUploadProgress}>{this.getString("competency.title")}</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 top-message">
                                {this.getString("competency.subheading").split("\n").map((line, i) => (
                                    <p key={i}>{line}</p>
                                ))}
                            </div>
                        </div>

                        {
                            this.state.competencyData.map((competency, index) => {
                                let competencyDetailItems = [];

                                if (competency != null) {
                                    competencyDetailItems.push(<div>{competency.cardType}</div>);
                                    competencyDetailItems.push(<div>{competency.idNumber}</div>);
                                    competencyDetailItems.push(<div>{this.getString("competency.expires", {date : moment(competency.expiryDate, "X").format("DD/MM/YYYY")})}</div>);
                                }

                                return (
                                    <div className="row" key={index}>
                                        <div className="col-12">
                                            <div className="competency-item">
                                                <div className="competency-detail">
                                                    {competencyDetailItems}
                                                </div>
                                                <div className="competency-action">
                                                    <span className="btn btn-success" onClick={() => this.launchCompetencyEditor(index)}>{this.getString("common.edit")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="row">
                            <div className="col-12">
                                <div className="bottom-button">
                                    <span className={"btn btn-success full-button" + mainSubmitExtraStyle} onClick={this.submitForm}>{this.getString("common.continue")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"modal fade" + modalExtraStyle}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.getString("competency.title")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCompetencyEditor}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="row form-block">
                                    <div className="col-12">
                                        <label>{this.getString("competency.frontOfCard")}</label>
                                        <div className="competency-image" style={{backgroundImage : "url(" + this.state.frontOfCard + ")"}}>
                                            <div className="content">
                                                <span className="btn btn-success" onClick={() => this.handleCompetencyPhotoClick(COMPETENCY_IMAGE_TYPE_FRONT)}>{this.getString("competency.updatePhoto")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-hide">
                                        <input type="file" ref={this.competencyFrontCard} onChange={(e) => this.handleCompetencyPhotoChange(e, COMPETENCY_IMAGE_TYPE_FRONT)} accept=".jpg, .jpeg, .png, .gif" />
                                    </div>
                                </div>

                                <div className="row form-block">
                                    <div className="col-12">
                                        <label>{this.getString("competency.backOfCard")}</label>
                                        <div className="competency-image" style={{backgroundImage : "url(" + this.state.backOfCard + ")"}}>
                                            <div className="content">
                                                <span className="btn btn-success" onClick={() => this.handleCompetencyPhotoClick(COMPETENCY_IMAGE_TYPE_BACK)}>{this.getString("competency.updatePhoto")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-hide">
                                        <input type="file" ref={this.competencyBackCard} onChange={(e) => this.handleCompetencyPhotoChange(e, COMPETENCY_IMAGE_TYPE_BACK)} />
                                    </div>
                                </div>

                                <div className="row form-block">
                                    <div className="col-12">
                                        <label>{this.getString("competency.cardType")}</label>
                                        <input type="text" className="form-control" name="cardType" onChange={this.handleChange} value={this.state.cardType} />
                                    </div>
                                </div>

                                <div className="row form-block">
                                    <div className="col-12">
                                        <label>{this.getString("competency.idNumber")}</label>
                                        <input type="text" className="form-control" name="idNumber" onChange={this.handleChange} value={this.state.idNumber} />
                                    </div>
                                </div>

                                <div className="row form-block">
                                    <div className="col-12">
                                        <label>{this.getString("competency.expiryDate")}</label>
                                        <small>&nbsp;{this.getString("competency.expiryHint")}</small>
                                        <input type="date" className="form-control" name="expiryDate" onChange={this.handleChange} value={this.state.expiryDate} />
                                    </div>
                                </div>

                            </div>

                            <div className="modal-footer">
                                <span className={"btn btn-success" + modalSubmitExtraStyle} onClick={this.saveCompetencyItemToState}>{this.getString("common.continue")}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {progressElement}
            </div>
        )
    }
}

export default InductionCompetencyLanding;