import React from "react";
import BaseComponent from "../BaseComponent";
import CommonHeader from "../common/CommonHeader";

import InductionSubmissionManager from "../../forms/InductionSubmissionManager";

import "../../assets/tsl/css/induction-user-details.css";

class InductionNextOfKinContact extends BaseComponent {

    inductionSubmissionManager = null;

    constructor(props, context) {
        super(props, context);

        this.inductionSubmissionManager = InductionSubmissionManager.getInstance();

        this.initState({
            nextOfKinName : this.inductionSubmissionManager.base.nextOfKinName,
            contactNumber: this.inductionSubmissionManager.base.nextOfKinContactNumber,
            address1: this.inductionSubmissionManager.base.nextOfKinAddressLine1,
            address2: this.inductionSubmissionManager.base.nextOfKinAddressLine2,
            addressCity: this.inductionSubmissionManager.base.nextOfKinAddressCity,
            addressCounty: this.inductionSubmissionManager.base.nextOfKinAddressCounty,
            addressPostcode: this.inductionSubmissionManager.base.nextOfKinAddressPostcode
        });

        this.validateForm = this.validateForm.bind(this);
        this.copyFromOperative = this.copyFromOperative.bind(this);
        this.submitForm= this.submitForm.bind(this);
    }

    validateForm() {
        let keys = [
            "contactNumber", "address1", "addressCity",
            "addressCounty", "addressPostcode", "nextOfKinName"
        ];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (this.state[key] !== undefined) {
                let value = this.state[key];

                if (value == null || value === "") {
                    return false;
                }
            }
        }

        return true;
    }

    copyFromOperative(event) {
        if (event.target.checked) {
            this.setState({
                //contactNumber: this.inductionSubmissionManager.base.contactNumber,
                address1: this.inductionSubmissionManager.base.homeAddressLine1,
                address2: this.inductionSubmissionManager.base.homeAddressLine2,
                addressCity: this.inductionSubmissionManager.base.homeAddressCity,
                addressCounty: this.inductionSubmissionManager.base.homeAddressCounty,
                addressPostcode: this.inductionSubmissionManager.base.homeAddressPostcode
            });
        } else {
            this.setState({
                contactNumber: "",
                address1: "",
                address2: "",
                addressCity: "",
                addressCounty: "",
                addressPostcode: ""
            });
        }

        this.setState({
            copyAddress : event.target.checked
        });
    }

    submitForm() {
        if (this.validateForm()) {
            this.inductionSubmissionManager.base.nextOfKinName = this.state.nextOfKinName;
            this.inductionSubmissionManager.base.nextOfKinAddressLine1 = this.state.address1;
            this.inductionSubmissionManager.base.nextOfKinAddressLine2 = this.state.address2;
            this.inductionSubmissionManager.base.nextOfKinAddressCity = this.state.addressCity;
            this.inductionSubmissionManager.base.nextOfKinAddressCounty = this.state.addressCounty;
            this.inductionSubmissionManager.base.nextOfKinAddressPostcode = this.state.addressPostcode
            this.inductionSubmissionManager.base.nextOfKinContactNumber = this.state.contactNumber;

            InductionSubmissionManager.save();

            window.location.href = "/induction/medical/";
        }
    }

    render() {
        let submitExtraClass = "";
        if (!this.validateForm()) {
            submitExtraClass = " disabled";
        }

        return (
            <div className="container induction-user-details-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4" />
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="screen-title">{this.getString("inductionUserDetails.title")}</div>
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.nextOfKinName")}</label>
                                <input type="text" className="form-control" name="nextOfKinName" onChange={this.handleChange} value={this.state.nextOfKinName} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label><input type="checkbox" name="copyAddress" onChange={this.copyFromOperative} value={this.state.copyAddress} /> {this.getString("inductionUserDetails.nextOfKinSame")}</label>
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.homeAddressLine1")}</label>
                                <input type="text" className="form-control" name="address1" onChange={this.handleChange} value={this.state.address1} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.homeAddressLine2")}</label>
                                <input type="text" className="form-control" name="address2" onChange={this.handleChange} value={this.state.address2} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.homeAddressCity")}</label>
                                <input type="text" className="form-control" name="addressCity" onChange={this.handleChange} value={this.state.addressCity} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.homeAddressCounty")}</label>
                                <input type="text" className="form-control" name="addressCounty" onChange={this.handleChange} value={this.state.addressCounty} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.homeAddressPostcode")}</label>
                                <input type="text" className="form-control" name="addressPostcode" onChange={this.handleChange} value={this.state.addressPostcode} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.contactNumber")}</label>
                                <input type="text" className="form-control" name="contactNumber" onChange={this.handleChange} value={this.state.contactNumber} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <span className={"btn btn-success full-button" + submitExtraClass} onClick={this.submitForm}>{this.getString("common.continue")}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default InductionNextOfKinContact;