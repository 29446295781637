import React from "react";
import BaseComponent from "../BaseComponent";
import CommonHeader from "../common/CommonHeader";

import { API } from "../../networking/API";

import "../../assets/tsl/css/induction-questionnaire.css";

class InductionQuestionnaireIntro extends BaseComponent {
    componentDidMount() {
        if (parseInt(this.getInductionConfig(API.INDUCTION_CONFIG.QUESTIONNAIRE_ACTIVE, 1)) === 0) {
            this.skipQuestionnaire();
        }
    }

    moveToNextScreen() {
        window.location.href = "/induction/questionnaire/";
    }

    skipQuestionnaire = () => {
        window.location.href = "/induction/submission/";
    }

    render() {
        if (parseInt(this.getInductionConfig(API.INDUCTION_CONFIG.QUESTIONNAIRE_ACTIVE, 1)) === 0) {
            return []; // Questionnaire is disabled, await navigation...
        }

        return (
            <div className="container induction-questionnaire-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4"/>
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="screen-title">{this.getString("questionnaire.title")}</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 screen-message">
                                {
                                    this.getString("questionnaire.message").split("\n").map((line, i) => (
                                        <p key={i}>{line}</p>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 bottom-button">
                                <span className="btn btn-success full-button" onClick={this.moveToNextScreen}>{this.getString("common.continue")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InductionQuestionnaireIntro;