import React from "react";

import BaseComponent from "../BaseComponent";
import InductionSubmissionManager from "../../forms/InductionSubmissionManager";
import CommonHeader from "../common/CommonHeader";

import "../../assets/tsl/css/induction-user-details.css";

class InductionUserDetails extends BaseComponent {

    inductionSubmissionManager = null;

    constructor(props, context) {
        super(props, context);

        this.inductionSubmissionManager = InductionSubmissionManager.getInstance();

        this.initState({
            fullName : this.inductionSubmissionManager.base.fullName,
            company : this.inductionSubmissionManager.base.companyName,
            trade : this.inductionSubmissionManager.base.occupation,
            email : this.inductionSubmissionManager.base.emailAddress,
            emailVerify : null
        });

        this.validateForm = this.validateForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    validateForm() {
        let keys = ["fullName", "company", "trade", "email", "emailVerify"];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (this.state[key] !== undefined) {
                let value = this.state[key];

                if (value == null || value === "") {
                    return false;
                }
            }
        }

        let email = this.state.email;
        let emailVerify = this.state.emailVerify;

        if (email !== emailVerify) {
            return false;
        }

        return true;
    }

    submitForm() {
        if (this.validateForm()) {
            this.inductionSubmissionManager.base.fullName = this.state.fullName;
            this.inductionSubmissionManager.base.companyName = this.state.company;
            this.inductionSubmissionManager.base.occupation = this.state.trade;
            this.inductionSubmissionManager.base.emailAddress = this.state.email;

            InductionSubmissionManager.save();

            window.location.href = "/induction/contact/";
        }
    }

    render() {
        let submitExtraClass = "";
        if (!this.validateForm()) {
            submitExtraClass = " disabled";
        }

        let emailValidationError = [];
        if ((this.state.email != null && this.state.emailVerify) && (this.state.email !== "" && this.state.emailVerify !== "")) {
            if (this.state.emailVerify !== this.state.email) {
                emailValidationError = (
                    <span className="basic-error">{this.getString("inductionUserDetails.emailMismatchError")}</span>
                )
            }
        }

        return (
            <div className="container induction-user-details-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4" />
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="screen-title">{this.getString("inductionUserDetails.title")}</div>
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.fullName")}</label>
                                <input type="text" className="form-control" name="fullName" onChange={this.handleChange} value={this.state.fullName} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.company")}</label>
                                <input type="text" className="form-control" name="company" onChange={this.handleChange} value={this.state.company} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.trade")}</label>
                                <input type="text" className="form-control" name="trade" onChange={this.handleChange} value={this.state.trade} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.email")}</label>
                                <input type="text" className="form-control" name="email" onChange={this.handleChange} value={this.state.email} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.emailVerify")}</label>
                                <input type="text" className="form-control" name="emailVerify" onChange={this.handleChange} value={this.state.emailVerify} />
                                {emailValidationError}
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <span className={"btn btn-success full-button" + submitExtraClass} onClick={this.submitForm}>{this.getString("common.continue")}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default InductionUserDetails;