import React from "react";

import "../../assets/tsl/css/progress-spinner.css";

class ProgressSpinner extends React.Component {

    render() {
        return (
            <div className="tsl-progress-spinner">
                <div className="colour colour-one" />
                <div className="colour colour-two" />
                <div className="colour colour-three" />
                <div className="colour colour-four" />
                <div className="colour colour-five" />
            </div>
        );
    }

}

export default ProgressSpinner;