import React from 'react';
import './App.css';
import MainComponent from "./components/MainComponent";

import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/tsl/css/common.css";

if (process.env.REACT_APP_CUSTOMER === "castlemore") {
  import("./assets/castlemore/css/common.css").then(() => console.log("OK"));
}


function App() {
  return (
    <MainComponent />
  );
}

export default App;
