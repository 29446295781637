import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Axios from "axios";

import BaseComponent from "./BaseComponent";
import InductionCountrySelection from "./induction/InductionCountrySelection";
import InductionStart from "./induction/InductionStart";
import InductionSignature from "./induction/InductionSignature";
import InductionPhotograph from "./induction/InductionPhotograph";
import InductionUserDetails from "./induction/InductionUserDetails";
import InductionUserContact from "./induction/InductionUserContact";
import InductionNextOfKinContact from "./induction/InductionNextOfKinContact";
import InductionMedicalDetails from "./induction/InductionMedicalDetails";
import InductionCompetencyLanding from "./induction/InductionCompetencyLanding";
import InductionVideoIntro from "./induction/InductionVideoIntro";
import InductionQuestionnaireIntro from "./induction/InductionQuestionnaireIntro";
import InductionQuestionnaireForm from "./induction/InductionQuestionnaireForm";
import InductionSubmission from "./induction/InductionSubmission";
import InductionFinish from "./induction/InductionFinish";

import { API, ENDPOINTS, INDUCTION_VIDEO_TYPES } from "../networking/API";

class MainComponent extends BaseComponent {
    componentDidMount() {
        super.componentDidMount();

        this.getInductionConfigFromNetwork();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getInductionConfigFromNetwork = () => {
        let fetch = true;

        let existingConfig = sessionStorage.getItem("induction_config");
        if (existingConfig !== undefined && existingConfig != null) {
            existingConfig = JSON.parse(existingConfig);
            if (existingConfig.hasOwnProperty("cacheDate")) {
                if ((existingConfig.cacheDate + 3600) > new Date().getTime()) {
                    fetch = false;
                }
            }
        }

        if (fetch) {
            Axios.get(ENDPOINTS.api.getInductionConfig)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        let data = resp.data;
                        data.cacheDate = new Date().getTime();

                        sessionStorage.setItem("induction_config", JSON.stringify(data));

                        if (window._inductionConfigDidChange !== undefined) {
                            window._inductionConfigDidChange(data);
                        }
                    }
                })
                .catch(console.log);
        }
    }

    render() {
        return(
            <BrowserRouter>
                <Switch>
                    <Route
                        path="/" exact
                        render={(props) =>
                            <InductionCountrySelection {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/start/"
                        render={(props) =>
                            <InductionStart {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/gdpr/"
                        render={(props) =>
                            <InductionSignature {...props} mode={InductionSignature.MODE_GDPR} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/photo/"
                        render={(props) =>
                            <InductionPhotograph {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/details/"
                        render={(props) =>
                            <InductionUserDetails {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/contact/"
                        render={(props) =>
                            <InductionUserContact {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/nextOfKin/"
                        render={(props) =>
                            <InductionNextOfKinContact {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/medical/"
                        render={(props) =>
                            <InductionMedicalDetails {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/declaration/"
                        render={(props) =>
                            <InductionSignature {...props} mode={InductionSignature.MODE_INDUCTION} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/rams/"
                        render={(props) =>
                            <InductionSignature {...props} mode={InductionSignature.MODE_RAMS} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/competency/"
                        render={(props) =>
                            <InductionCompetencyLanding {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/video-intro/"
                        render={(props) =>
                            <InductionVideoIntro {...props} videoType={INDUCTION_VIDEO_TYPES.introduction} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/questionnaire-intro/"
                        render={(props) =>
                            <InductionQuestionnaireIntro {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/questionnaire/"
                        render={(props) =>
                            <InductionQuestionnaireForm {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/submission/"
                        render={(props) =>
                            <InductionSubmission {...props} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/video-covid/"
                        render={(props) =>
                            <InductionVideoIntro {...props} videoType={INDUCTION_VIDEO_TYPES.COVID} />
                        }
                        forceReg={true} />

                    <Route
                        path="/induction/finish/"
                        render={(props) =>
                            <InductionFinish {...props} />
                        }
                        forceReg={true} />

                </Switch>

            </BrowserRouter>
        );
    }
}

export default MainComponent;