import React from "react";
import BaseComponent from "../BaseComponent";

class CommonHeader extends BaseComponent {

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="common-logo" />
                </div>
            </div>
        )
    }
    
}

export default CommonHeader;