import React from 'react';
import Axios from 'axios';

import BaseComponent from '../BaseComponent';
import CommonHeader from '../common/CommonHeader';
import InductionSubmissionManager from '../../forms/InductionSubmissionManager';

import { API } from '../../networking/API';

import '../../assets/tsl/css/induction-photograph.css';

class InductionPhotograph extends BaseComponent {

    inductionSubmissionManager = InductionSubmissionManager.getInstance();

    constructor(props, context) {
        super(props, context);

        this.state = {
            photoFile : null, //photoFile,
            photoUri : null,
            imageNetworkInFlight : false,
            uploadProgress : 0
        };

        this.photoInput = React.createRef();

        this.onPhotoChange = this.onPhotoChange.bind(this);
        this.loadPhotoPreview = this.loadPhotoPreview.bind(this);
        this.moveToNextScreen = this.moveToNextScreen.bind(this);
        this.uploadTemporaryImage = this.uploadTemporaryImage.bind(this);
        this.debugShowUploader = this.debugShowUploader.bind(this);
    }

    onPhotoChange(event) {
        let target = event.target;

        if (target.files.length > 0) {
            let photo = target.files[0];

            this.setState({
                photoFile : photo
            });

            this.loadPhotoPreview(photo);
        }
    }

    loadPhotoPreview(photoFile) {
        let reader = new FileReader();
        reader.onload = () => {
            this.setState({
                photoUri : reader.result
            });
        };
        reader.readAsDataURL(photoFile);
    }

    uploadTemporaryImage() {
        this.setState({
            imageNetworkInFlight: true
        });

        let formData = new FormData();
        formData.append('image', this.state.photoFile);

        const config = {
            method: 'post',
            url: 'https://tsl.developyn.com/api/upload_induction_temporary_image',
            data: formData,
            onUploadProgress: data => {
                if (!data?.lengthComputable) return;
                let percent = (data?.loaded / data?.total) * 100;

                this.setState({
                    uploadProgress: percent
                });
            }
        };

        Axios(config).then(r => {
            let resp = API.parse(r);
            if (resp.success) {
                this.inductionSubmissionManager.photos.currentPhotoId = resp?.data.imageId;

                this.moveToNextScreen();
            } else {
                console.log(resp.error);
            }
        })
        .catch(console.log);
    }

    moveToNextScreen() {
        InductionSubmissionManager.save();

        window.location.href = "/induction/details/";
    }

    debugShowUploader() {
        this.setState({
            imageNetworkInFlight : true,
            uploadProgress : 50
        });
    }

    render() {
        let progressElement = [];
        if (this.state.imageNetworkInFlight) {
            progressElement = (
                <div className="blocker">
                    <div className="blocker-content">
                        <div className="progress-area">
                            <div className="progress-title">
                                {this.getString("submission.progress", { percentage : this.state.uploadProgress.toFixed(0)})}
                            </div>
                            <div className="progress-upload">
                                <div className="progress-upload-bar" style={{width : this.state.uploadProgress + "%"}} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="container induction-photograph-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4" />
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="screen-title" onClick={this.debugShowUploader}>{this.getString("inductionPhoto.title")}</div>
                        <div className="photo-container" style={{backgroundImage : "url(" + this.state.photoUri + ")"}}>
                            <div className="content">
                                <span className="btn btn-success" onClick={() => this.photoInput.current.click()}>
                                    {this.getString("inductionPhoto.takePhoto")}
                                </span>
                            </div>
                        </div>
                        <div className="bottom-message">
                            <p>{this.getString("inductionPhoto.photoHint")}</p>
                            {
                                this.getString("inductionPhoto.photoNotice").split("\n").map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))
                            }
                        </div>
                        <div className="bottom-buttons">
                            <span className="btn btn-success full-button" onClick={this.uploadTemporaryImage}>{this.getString("common.continue")}</span>
                        </div>
                    </div>
                </div>

                {progressElement}

                <div className="input-hide">
                    <input type="file" ref={this.photoInput} onChange={this.onPhotoChange} accept=".jpg, .jpeg, .png, .gif" />
                </div>
            </div>
        )
    }
}

export default InductionPhotograph;