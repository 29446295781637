import React from "react";
import BaseComponent from "../BaseComponent";
import InductionSubmissionManager from "../../forms/InductionSubmissionManager";
import CommonHeader from "../common/CommonHeader";

class InductionMedicalDetails extends BaseComponent {

    inductionSubmissionManager = InductionSubmissionManager.getInstance();

    constructor(props, context) {
        super(props, context);

        this.initState({
            medicalCondition: null,
            medicalConditionOptOut: false
        });

        this.validateForm = this.validateForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    validateForm() {
        if (!this.state.medicalConditionOptOut) {
            if (this.state.medicalCondition == null || this.state.medicalCondition === "") {
                return false;
            }
        }
        return true;
    }

    submitForm() {
        if (this.validateForm()) {
            this.inductionSubmissionManager.base.medicalCondition = this.state.medicalConditionOptOut ? 0 : 1;
            this.inductionSubmissionManager.base.medicalConditionText = this.state.medicalCondition;

            InductionSubmissionManager.save();

            window.location.href = "/induction/declaration/";
        }
    }

    render() {
        let submitExtraClass = "";
        if (!this.validateForm()) {
            submitExtraClass = " disabled";
        }

        return (
            <div className="container induction-user-details-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4" />
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="screen-title">{this.getString("inductionUserDetails.title")}</div>
                            </div>
                        </div>

                        <div className="row form-row text-center">
                            <div className="col-12">
                                <strong>{this.getString("inductionUserDetails.medicalIntro")}</strong>
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.medicalLabel")}</label>
                                <textarea className="form-control" onChange={this.handleChange} name="medicalCondition" value={this.state.medicalCondition} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label><input type="checkbox" onChange={this.handleChange} name="medicalConditionOptOut" checked={this.state.medicalConditionOptOut} /> {this.getString("inductionUserDetails.medicalOptOut")}</label>
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <span className={"btn btn-success full-button" + submitExtraClass} onClick={this.submitForm}>{this.getString("common.continue")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InductionMedicalDetails;