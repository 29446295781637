import React from "react";
import Axios from "axios";

import BaseComponent from "../BaseComponent";
import CommonHeader from "../common/CommonHeader";
import InductionSubmissionManager from "../../forms/InductionSubmissionManager";

import { API, ENDPOINTS } from "../../networking/API";

import "../../assets/tsl/css/induction-submission.css";

class InductionSubmission extends BaseComponent {

    inductionSubmissionManager = InductionSubmissionManager.getInstance();

    inductionUserId = null;
    inductionNumber = null;

    constructor(props, context) {
        super(props, context);

        this.initState({
            uploadProgress : 0,
            inductionUserId : null,
            inductionNumber : null,
            photoCount : 0,
            photosUploaded : 0
        });

        this.startUpload = this.startUpload.bind(this);
        this.uploadInductionUserImage = this.uploadInductionUserImage.bind(this);
        this.processCompetency = this.processCompetency.bind(this);
        this.uploadCompetencyImage = this.uploadCompetencyImage.bind(this);
        this.uploadQuestionnaireAnswers = this.uploadQuestionnaireAnswers.bind(this);
        this.moveToNextScreen = this.moveToNextScreen.bind(this);
        this.calculateProgressPercentage = this.calculateProgressPercentage.bind(this);
        this.incrementUploadedPhotos = this.incrementUploadedPhotos.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        if (!this.inductionSubmissionManager.successfullySubmitted) {
            this.startUpload();
        } else {
            this.setState({
                uploadProgress : 100
            });
            this.moveToNextScreen();
        }
    }

    startUpload() {
        // Work out how many photos we're going to upload
        let photoCount = 0;
        if (this.inductionSubmissionManager.photos.currentPhoto != null) {
            photoCount++;
        }

        if (this.inductionSubmissionManager.competency.length > 0) {
            for (let i = 0; i < this.inductionSubmissionManager.competency.length; i++) {
                let competency = this.inductionSubmissionManager.competency[i];

                if (competency != null) {
                    if (competency.frontCardUri != null) {
                        photoCount++;
                    }

                    if (competency.backCardUri != null) {
                        photoCount++;
                    }
                }
            }
        }

        this.setState({
            photoCount : photoCount
        });

        // Submit
        let inductionUserValidationData = this.validateCreateFormData(this.inductionSubmissionManager.base, [
            { key : "countryCode", type : "string", label : "Country Code" },
            { key : "fullName", type : "string", label : "Full Name" },
            { key : "companyName", type : "string", label : "companyName" },
            { key : "occupation", type : "string", label : "occupation" },
            { key : "emailAddress", type : "string", label : "emailAddress" },
            { key : "contactNumber", type : "string", label : "contactNumber" },
            { key : "homeAddressLine1", type : "string", label : "homeAddressLine1" },
            { key : "homeAddressLine2", type : "string", label : "homeAddressLine2", optional : true },
            { key : "homeAddressCity", type : "string", label : "homeAddressCity" },
            { key : "homeAddressCounty", type : "string", label : "homeAddressCounty" },
            { key : "homeAddressPostcode", type : "string", label : "homeAddressPostcode" },
            { key : "dateOfBirth", type : "int", label : "dateOfBirth" },
            { key : "nextOfKinName", type : "string", label : "nextOfKinName" },
            { key : "nextOfKinAddressLine1", type : "string", label : "nextOfKinAddressLine1" },
            { key : "nextOfKinAddressLine2", type : "string", label : "nextOfKinAddressLine2", optional : true },
            { key : "nextOfKinAddressCity", type : "string", label : "nextOfKinAddressCity" },
            { key : "nextOfKinAddressCounty", type : "string", label : "nextOfKinAddressCounty" },
            { key : "nextOfKinAddressPostcode", type : "string", label : "nextOfKinAddressPostcode" },
            { key : "nextOfKinContactNumber", type : "string", label : "nextOfKinContactNumber" },
            { key : "medicalCondition", type : "int", label : "medicalCondition" },
            { key : "medicalConditionText", type : "string", label : "medicalConditionText", optional: true },
            { key : "declarationName", type : "string", label : "declarationName" },
            { key : "RAMSName", type : "string", label : "RAMSName" },
            { key : "GDPRName", type : "string", label : "GDPRName" },
        ]); 

        if (inductionUserValidationData.success) {
            let formData = inductionUserValidationData.formData;

            Axios.post(ENDPOINTS.api.submitInductionUser, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        this.setState({
                            inductionUserId : resp.data.inductionUserId,
                            inductionNumber : resp.data.inductionNumber
                        });

                        this.inductionUserId = resp.data.inductionUserId;
                        this.inductionNumber = resp.data.inductionNumber;

                        this.uploadInductionUserImage();
                    }
                })
                .catch(console.log);
        }
    }

    uploadInductionUserImage() {
        if (this.inductionSubmissionManager.photos.currentPhotoId !== null) {
            //let blobData = this.b64toBlob(this.inductionSubmissionManager.photos.currentPhoto);

            let formData = new FormData();
            formData.append("inductionUserId", this.inductionUserId);
            //formData.append("image", blobData.blob, "inductionUserImage_" + moment().format("X") + blobData.extension);
            formData.append("temporaryImageId", this.inductionSubmissionManager.photos.currentPhotoId.toString());

            Axios.post(ENDPOINTS.api.uploadInductionUserImage, formData, {
                    onUploadProgress: (data) => {
                        if (data.lengthComputable) {
                            var percent = this.calculateProgressPercentage((data.loaded / data.total) * 100);

                            this.setState({
                                uploadProgress: percent
                            });
                        }
                    }
                })
                .then(() => {
                    this.incrementUploadedPhotos();
                    this.processCompetency(0);
                })
                .catch(console.log)
        } else {
            this.processCompetency(0);
        }
    }

    processCompetency(index) {
        if (this.inductionSubmissionManager.competency.length > index) {
            let competency = this.inductionSubmissionManager.competency[index];

            if (competency != null) {
                let formData = new FormData();
                formData.append("inductionUserId", this.inductionUserId);
                formData.append("cardType", competency.cardType);
                formData.append("cardNumber", competency.idNumber);
                formData.append("expiryDate", competency.expiryDate);

                Axios.post(ENDPOINTS.api.submitInductionUserCompetency, formData)
                    .then((r) => {
                        let resp = API.parse(r);
                        if (resp.success) {
                            this.uploadCompetencyImage(resp.data.inductionUserCompetencyId, index, 0);
                        }
                    })
                    .catch(console.log)
            } else {
                this.processCompetency(index + 1);
            }
        } else {
            this.uploadQuestionnaireAnswers();
        }
    }

    uploadCompetencyImage(inductionUserCompetencyId, index, imageIndex) {
        let competency = this.inductionSubmissionManager.competency[index];

        if (competency != null) {
            let imageId = null;
            if (imageIndex === 0) {
                imageId = competency.frontCardId;
            } else {
                imageId = competency.backCardId;
            }

            if (imageId != null) {
                let formData = new FormData();
                formData.append("inductionUserCompetencyId", inductionUserCompetencyId);
                formData.append("temporaryImageId", imageId);

                Axios.post(ENDPOINTS.api.uploadInductionUserCompetencyImage, formData, {
                        onUploadProgress: (data) => {
                            if (data.lengthComputable) {
                                var percent = this.calculateProgressPercentage((data.loaded / data.total) * 100);

                                this.setState({
                                    uploadProgress: percent
                                });
                            }
                        }
                    })
                    .then((r) => {
                        this.incrementUploadedPhotos();
                        if (imageIndex === 0) {
                            this.uploadCompetencyImage(inductionUserCompetencyId, index, 1);
                        } else {
                            this.processCompetency(index + 1);
                        }
                    })
                    .catch(console.log);
            } else {
                if (imageIndex === 0) {
                    this.uploadCompetencyImage(inductionUserCompetencyId, index, 1);
                } else {
                    this.processCompetency(index + 1);
                }
            }
        } else {
            this.processCompetency(index + 1);
        }
    }

    uploadQuestionnaireAnswers() {
        let formData = new FormData();
        formData.append("inductionUserId", this.inductionUserId);
        formData.append("score", this.inductionSubmissionManager.questionnaireScore);
        formData.append("items", JSON.stringify(this.inductionSubmissionManager.questionnaire));

        Axios.post(ENDPOINTS.api.submitInductionUserQuestionnaireAnswers, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.moveToNextScreen();
                }
            })
            .catch(console.log);
    }

    moveToNextScreen() {
        this.inductionSubmissionManager.inductionUserId = this.inductionUserId;
        this.inductionSubmissionManager.inductionNumber = this.inductionNumber;
        this.inductionSubmissionManager.successfullySubmitted = true;

        InductionSubmissionManager.save();

        // window.location.href = "/induction/video-covid/"; // Skip the COVID Video
        window.location.href = '/induction/finish/';
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        //data:image/jpeg;base64
        let parsed = b64Data.split(",");
        if (parsed.length > 0) {
            let metaString = parsed[0];
            let metaSplit = metaString.split(";");

            let dataPart = metaSplit[0];
            let dataSplit = dataPart.split(":");
            contentType = dataSplit[1];

            b64Data = parsed[1];
        }

        let fileextension = ".bin";
        if (contentType === undefined) {
            contentType = "";
        } else {
            if (contentType === "image/jpeg" || contentType === "image/jpg") {
                fileextension = ".jpg";
            } else if (contentType === "image/png") {
                fileextension = ".png";
            } else if (contentType === "image/gif") {
                fileextension = ".gif";
            }
        }

        if (sliceSize === undefined) {
            sliceSize = 512;
        }

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return {
            blob : new Blob(byteArrays, {type: contentType}),
            extension : fileextension,
            contentType : contentType
        };
    }

    calculateProgressPercentage(percent) {
        let existingPercent = this.state.photosUploaded * 100;
        let newOverallPercent = existingPercent + percent;

        return this.rangeConvert(newOverallPercent, [0, this.state.photoCount * 100], [0, 100]);
    }

    rangeConvert(value, r1, r2) {
        return ( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ];
    }

    incrementUploadedPhotos() {
        let photosUploaded = this.state.photosUploaded;
        this.setState({
            photosUploaded : (photosUploaded + 1)
        });
    }

    render() {
        return (
            <div className="container induction-submission-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4"/>
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="screen-title">{this.getString("questionnaire.title")}</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="screen-message">{this.getString("submission.message")}</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="progress-area">
                                    <div className="progress-title">{this.getString("submission.progress", { percentage : (isNaN(this.state.uploadProgress) ? 0 : this.state.uploadProgress.toFixed(0)) })}</div>
                                    <div className="progress-upload">
                                        <div className="progress-upload-bar" style={{width:this.state.uploadProgress + "%"}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default InductionSubmission;