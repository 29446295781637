import React from "react";
import moment from "moment";

import BaseComponent from "../BaseComponent";
import InductionSubmissionManager from "../../forms/InductionSubmissionManager";
import CommonHeader from "../common/CommonHeader";

import "../../assets/tsl/css/induction-user-details.css";

class InductionUserContact extends BaseComponent {

    inductionSubmissionManager = null;

    constructor(props, context) {
        super(props, context);

        this.inductionSubmissionManager = InductionSubmissionManager.getInstance();

        let initialDateOfBirth = moment().format("YYYY-MM-DD");
        if (this.inductionSubmissionManager.base.dateOfBirth != null) {
            initialDateOfBirth = moment(this.inductionSubmissionManager.base.dateOfBirth, "X").format("YYYY-MM-DD");
        }

        this.initState({
            contactNumber : this.inductionSubmissionManager.base.contactNumber,
            address1 : this.inductionSubmissionManager.base.homeAddressLine1,
            address2 : this.inductionSubmissionManager.base.homeAddressLine2,
            addressCity : this.inductionSubmissionManager.base.homeAddressCity,
            addressCounty : this.inductionSubmissionManager.base.homeAddressCounty,
            addressPostcode : this.inductionSubmissionManager.base.homeAddressPostcode,
            dateOfBirth : initialDateOfBirth,
            age : null
        });

        this.validateForm = this.validateForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    validateForm() {
        let keys = [
            "contactNumber", "address1", "addressCity",
            "addressCounty", "addressPostcode", "dateOfBirth"
        ];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (this.state[key] !== undefined) {
                let value = this.state[key];

                if (value == null || value === "") {
                    return false;
                }
            }
        }

        let date = this.state.dateOfBirth;
        if (date !== null) {
            if (!moment(date).isValid()) {
                return false;
            }
        }

        return true;
    }

    submitForm() {
        if (this.validateForm()) {
            this.inductionSubmissionManager.base.contactNumber = this.state.contactNumber;
            this.inductionSubmissionManager.base.homeAddressLine1 = this.state.address1;
            this.inductionSubmissionManager.base.homeAddressLine2 = this.state.address2;
            this.inductionSubmissionManager.base.homeAddressCity = this.state.addressCity;
            this.inductionSubmissionManager.base.homeAddressCounty = this.state.addressCounty;
            this.inductionSubmissionManager.base.homeAddressPostcode = this.state.addressPostcode;
            this.inductionSubmissionManager.base.dateOfBirth = moment(this.state.dateOfBirth).format("X");

            InductionSubmissionManager.save();

            window.location.href = "/induction/nextOfKin/";
        }
    }

    render() {
        let submitExtraClass = "";
        if (!this.validateForm()) {
            submitExtraClass = " disabled";
        }

        return (
            <div className="container induction-user-details-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4" />
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="screen-title">{this.getString("inductionUserDetails.title")}</div>
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.contactNumber")}</label>
                                <input type="text" className="form-control" name="contactNumber" onChange={this.handleChange} value={this.state.contactNumber} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.homeAddressLine1")}</label>
                                <input type="text" className="form-control" name="address1" onChange={this.handleChange} value={this.state.address1} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.homeAddressLine2")}</label>
                                <input type="text" className="form-control" name="address2" onChange={this.handleChange} value={this.state.address2} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.homeAddressCity")}</label>
                                <input type="text" className="form-control" name="addressCity" onChange={this.handleChange} value={this.state.addressCity} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.homeAddressCounty")}</label>
                                <input type="text" className="form-control" name="addressCounty" onChange={this.handleChange} value={this.state.addressCounty} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.homeAddressPostcode")}</label>
                                <input type="text" className="form-control" name="addressPostcode" onChange={this.handleChange} value={this.state.addressPostcode} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.dateOfBirth")}</label>
                                <input type="date" className="form-control" name="dateOfBirth" onChange={this.handleChange} value={this.state.dateOfBirth} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <label>{this.getString("inductionUserDetails.age")}</label>
                                <input type="number" className="form-control" name="age" onChange={this.handleChange} value={this.state.age} />
                            </div>
                        </div>

                        <div className="row form-row">
                            <div className="col-12">
                                <span className={"btn btn-success full-button" + submitExtraClass} onClick={this.submitForm}>{this.getString("common.continue")}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default InductionUserContact;