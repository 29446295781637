import React from "react";
import BaseComponent from "../BaseComponent";
import CommonHeader from "../common/CommonHeader";
import InductionSubmissionManager from "../../forms/InductionSubmissionManager";

const QUESTION_NUMBER_TOTAL = 15;
const PASSING_GRADE = 13;

class InductionQuestionnaireForm extends BaseComponent {

    questions = [];
    answers = [];

    constructor(props, context) {
        super(props, context);

        this.initState({
            questionIndex : 0,
            selectedAnswerIndex : -1,
            showResults : false,
            correctAnswers : 0,
            attempt : 0
        });

        this.questions = this.createQuestionnaireQuestions();

        this.createQuestionnaireQuestions = this.createQuestionnaireQuestions.bind(this);
        this.handleAnswerSelection = this.handleAnswerSelection.bind(this);
        this.submitAnswer = this.submitAnswer.bind(this);
        this.goToPrevious = this.goToPrevious.bind(this);
        this.calculateCorrectAnswerCount = this.calculateCorrectAnswerCount.bind(this);
        this.handleFailureButtonPress = this.handleFailureButtonPress.bind(this);
        this.handleSuccessButtonPress = this.handleSuccessButtonPress.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    createQuestionnaireQuestions() {
        let answers = [1,3,2,2,2,3,3,2,1,3,3,1,2,2,1];
        let questions = [];

        let letters = ["a","b","c","d"];

        for (let i = 0; i < answers.length; i++) {
            let questionText = this.getString("questionnaire.question" + (i + 1));
            let correctIndex = answers[i];
            let questionAnswers = [];

            for (let x = 0; x < 4; x++) {
                questionAnswers.push(this.getString("questionnaire.question" + (i + 1) + letters[x]));
            }

            questions.push({
                title : questionText,
                answers : questionAnswers,
                answer : correctIndex
            });
        }

        return questions;
    }

    handleAnswerSelection(index) {
        this.setState({
            selectedAnswerIndex : index
        });
    }

    submitAnswer() {
        if (this.state.selectedAnswerIndex === -1) {
            return;
        }

        let question = this.questions[this.state.questionIndex];

        let answerData = {
            title : question.title,
            answerIndex : this.state.selectedAnswerIndex,
            answer : question.answers[this.state.selectedAnswerIndex]
        };

        if (this.answers.length > this.state.questionIndex) {
            this.answers[this.state.questionIndex] = answerData;
        } else {
            this.answers.push(answerData);
        }

        let newQuestionIndex = this.state.questionIndex + 1;
        if (newQuestionIndex >= this.questions.length) {
            let correctCount = this.calculateCorrectAnswerCount();

            this.setState({
                showResults : true,
                correctAnswers : correctCount
            });
        } else {
            let selectedAnswer = -1;
            if (this.answers.length > newQuestionIndex) {
                selectedAnswer = this.answers[newQuestionIndex].answerIndex;
            }

            this.setState({
                questionIndex : newQuestionIndex,
                selectedAnswerIndex : selectedAnswer
            });
        }
    }

    goToPrevious() {
        if (this.state.questionIndex > 0) {
            let newIndex = this.state.questionIndex - 1;
            let selectedAnswer = -1;

            if (this.answers.length > newIndex) {
                selectedAnswer = this.answers[newIndex].answerIndex;
            }

            this.setState({
                questionIndex : newIndex,
                selectedAnswerIndex : selectedAnswer
            });
        }
    }

    calculateCorrectAnswerCount() {
        let correctCount = 0;

        for (let i = 0; i < this.answers.length; i++) {
            let question = this.questions[i];
            let answer = this.answers[i];

            if (answer.answerIndex === question.answer) {
                correctCount++;
            }
        }

        return correctCount;
    }

    handleFailureButtonPress() {
        let attempt = this.state.attempt + 1;

        if (attempt < 2) {
            this.answers = [];

            this.setState({
                questionIndex: 0,
                showResults: false,
                correctAnswers: 0,
                selectedAnswerIndex: -1,
                attempt: attempt
            });
        } else {
            window.location.href = "/induction/video-intro/";
        }
    }

    handleSuccessButtonPress() {
        let inductionSubmissionManager = InductionSubmissionManager.getInstance();
        inductionSubmissionManager.questionnaireScore = this.state.correctAnswers;
        inductionSubmissionManager.questionnaire = this.answers;

        InductionSubmissionManager.save();

        window.location.href = "/induction/submission/";
    }

    render() {
        let contentArea = [];

        if (!this.state.showResults) {
            let question = this.questions[this.state.questionIndex];

            let prevButtonExtraClass = "";
            if (this.state.questionIndex === 0) {
                prevButtonExtraClass = " disabled";
            }

            let nextButtonExtraClass = "";
            if (this.state.selectedAnswerIndex === -1) {
                nextButtonExtraClass = " disabled";
            }

            contentArea = (
                <div>
                    <div className="row">
                        <div className="col-12">
                            <div className="question-number">{this.getString("questionnaire.questionNumber", { position : (this.state.questionIndex + 1), total : QUESTION_NUMBER_TOTAL })}</div>
                        </div>
                    </div>

                    <div className="row question">
                        <div className="col-12">
                            <div className="question-title">{question.title}</div>
                            <div className="question-answers">
                                {
                                    question.answers.map((answer, index) => (
                                        <label key={index}><input type="radio" checked={this.state.selectedAnswerIndex === index} onChange={() => this.handleAnswerSelection(index)} /> {answer}</label>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row bottom-button">
                        <div className="col-12 col-md-6">
                            <span className={"btn btn-success full-button" + prevButtonExtraClass} onClick={this.goToPrevious}>{this.getString("common.previous")}</span>
                        </div>

                        <div className="col-12 col-md-6">
                            <span className={"btn btn-success full-button" + nextButtonExtraClass} onClick={this.submitAnswer}>{this.getString("common.next")}</span>
                        </div>
                    </div>
                </div>
            );
        } else {
            if (this.state.correctAnswers >= PASSING_GRADE) {
                contentArea = (
                    <div className="result-success">
                        <div className="result-title">{this.getString("questionnaire.resultCongratulations")}</div>
                        <div className="result-message">{this.getString("questionnaire.resultCongratsSubtitle")}</div>
                        <div className="result-score-title">{this.getString("questionnaire.resultYourScore")}</div>
                        <div className="result-score">{this.getString("questionnaire.resultScore", {correct : this.state.correctAnswers, total : QUESTION_NUMBER_TOTAL})}</div>
                        <div className="result-footer">{this.getString("questionnaire.resultFooter")}</div>

                        <div className="bottom-button">
                            <div className="btn btn-success full-button" onClick={this.handleSuccessButtonPress}>{this.getString("common.continue")}</div>
                        </div>
                    </div>
                )
            } else {
                contentArea = (
                    <div className="result-failure">
                        <div className="result-message">
                            {
                                this.getString("questionnaire.resultFailure" + (this.state.attempt + 1)).split("\n").map((line, i) => (
                                    <p key={i}>{line}</p>
                                ))
                            }
                        </div>

                        <div className="bottom-button">
                            <div className="bottom-button">
                                <div className="btn btn-success full-button" onClick={this.handleFailureButtonPress}>{this.getString("common.continue")}</div>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        return (
            <div className="container induction-questionnaire-screen">
                <CommonHeader />

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4"/>
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="screen-title">{this.getString("questionnaire.title")}</div>
                            </div>
                        </div>

                        {contentArea}
                    </div>
                </div>
            </div>
        );
    }

}

export default InductionQuestionnaireForm;
