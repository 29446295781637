import React from "react";
import BaseComponent from "../BaseComponent";
import CommonHeader from "../common/CommonHeader";

import "../../assets/tsl/css/induction-video.css";
import Axios from "axios";
import { API, ENDPOINTS, INDUCTION_VIDEO_TYPES } from "../../networking/API";
import InductionSubmissionManager from "../../forms/InductionSubmissionManager";
import ProgressSpinner from "../common/ProgressSpinner";

import playIcon from "../../assets/tsl/img/play.svg";
import pauseIcon from "../../assets/tsl/img/pause.svg";
import fullscreenIcon from "../../assets/tsl/img/fullscreen.svg";
import fullscreenExitIcon from "../../assets/tsl/img/fullscreen_exit.svg";

class InductionVideoIntro extends BaseComponent {

    inductionSubmissionManager = InductionSubmissionManager.getInstance();

    input = "";

    videoTypeId = INDUCTION_VIDEO_TYPES.introduction;

    videoControlsTimeout = undefined;

    constructor(props, context) {
        super(props, context);

        if (props.videoType !== undefined) {
            this.videoTypeId = props.videoType;
        }

        let titleTextKey = "inductionVideo.introTitle";
        let introTextKey = "inductionVideo.introInfo";
        if (this.videoTypeId === INDUCTION_VIDEO_TYPES.COVID) {
            titleTextKey = "inductionVideo.covidTitle";
            introTextKey = "inductionVideo.covidInfo";
        }

        this.initState({
            videoNetworkInFlight : false,
            videoUri : null,
            videoDuration : 0,
            showVideo : false,
            videoPlaying : true,
            videoFullscreen : false,
            videoPlaybackDuration : 0,
            videoPlaybackPosition : 0,
            titleStringRes : titleTextKey,
            infoStringRes : introTextKey,
            videoControlsOpacity : 0
        });

        this.videoContainer = React.createRef();
        this.videoPlayer = React.createRef();
        this.audioPlayer = React.createRef();

        this.getVideoInfoFromNetwork = this.getVideoInfoFromNetwork.bind(this);
        this.showVideo = this.showVideo.bind(this);
        this.playPause = this.playPause.bind(this);
        this.fullscreen = this.fullscreen.bind(this);
        this.moveToNextScreen = this.moveToNextScreen.bind(this);
        this.controlVideoControlVisibility = this.controlVideoControlVisibility.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.getInductionVideoConfigFromNetwork();

        if (this.checkConfigStatus()) {
            this.performScreenSetup();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        clearTimeout(this.videoControlsTimeout);

        window._inductionConfigDidChange = undefined;
    }

    checkConfigStatus = () => {
        let configCheckKey = API.INDUCTION_CONFIG.INDUCTION_VIDEO_ACTIVE;
        if (this.videoTypeId === INDUCTION_VIDEO_TYPES.COVID) {
            configCheckKey = API.INDUCTION_CONFIG.COVID_VIDEO_ACTIVE;
        }

        let value = parseInt(this.getInductionConfig(configCheckKey, 1));

        if (value === 0) {
            this.moveToNextScreen();

            return false;
        }
        return true;
    }

    performScreenSetup = () => {
        this.videoPlayer.current.addEventListener("timeupdate", () => {
            let duration = this.videoPlayer.current.duration;
            let position = this.videoPlayer.current.currentTime;

            this.setState({
                videoPlaybackDuration : duration,
                videoPlaybackPosition : position
            });

            if (position >= duration) {
                this.moveToNextScreen();
            }
        });

        this.videoPlayer.current.addEventListener("canplay", () => {
            this.setState({
                videoPlayerIsReady : true
            }, () => {
                this.readyStateDidChange();
            });
        })

        this.audioPlayer.current.addEventListener("canplay", () => {
            this.setState({
                audioPlayerIsReady : true
            }, () => {
                this.readyStateDidChange();
            });
        });

        this.videoPlayer.current.addEventListener("waiting", () => {
            this.setState({
                videoPlayerIsReady : false
            }, () => {
                this.readyStateDidChange();
            });
        });

        this.audioPlayer.current.addEventListener("waiting", () => {
            this.setState({
                audioPlayerIsReady : false
            }, () => {
                this.readyStateDidChange();
            });
        });

        this.videoPlayer.current.addEventListener("loadstart", () => {
        });

        this.audioPlayer.current.addEventListener("loadstart", () => {
        });

        this.videoPlayer.current.addEventListener("pause", () => {
            if (this.state.audioUri) {
                this.audioPlayer.current.pause();
            }
        });

        this.videoPlayer.current.addEventListener("play", () => {
            if (this.state.audioUri) {
                this.audioPlayer.current.play();
            }
        })

        // Shh
        document.addEventListener('keydown', (e) => {
            let key = '38384040373937396665'; // You'll know this if you've played Contra
            this.input += ("" + e.keyCode);
            if (this.input === key) {
                return this.videoPlayer.current.currentTime = this.videoPlayer.current.duration - 10;
            }
            if (!key.indexOf(this.input)) return;
            this.input = ("" + e.keyCode);
        });
    }

    readyStateDidChange = () => {
        if (this.state.videoPlayerIsReady && (this.state.audioUri == null || this.state.audioPlayerIsReady)) {
            // Both are ready, let's go!
            this.videoPlayer.current.play();

            if (this.state.audioUri !== null) {
                // If there's an audio track, mute the video and play the audio
                this.videoPlayer.current.volume = 0;
                this.audioPlayer.current.play();
            } else {
                // Otherwise, make sure the video track audio is audible.
                this.videoPlayer.current.volume = 1;
            }
        } else {
            this.videoPlayer.current.pause();
            this.audioPlayer.current.pause();
        }
    }

    moveToNextScreen() {
        if (this.videoTypeId === INDUCTION_VIDEO_TYPES.introduction) {
            let inductionVideoEnabled = parseInt(this.getInductionConfig(API.INDUCTION_CONFIG.INDUCTION_VIDEO_ACTIVE)) === 1;
            let questionnaireEnabled = parseInt(this.getInductionConfig(API.INDUCTION_CONFIG.QUESTIONNAIRE_ACTIVE, 1)) === 1;
            let covidVideoEnabled = parseInt(this.getInductionConfig(API.INDUCTION_CONFIG.COVID_VIDEO_ACTIVE, 1)) === 1;

            if (!(inductionVideoEnabled && questionnaireEnabled && covidVideoEnabled)) {
                window.location = "/induction/submission/";
            }

            window.location.href = "/induction/questionnaire-intro/";
        } else {
            window.location.href = "/induction/finish/";
        }
    }

    getVideoInfoFromNetwork() {
        this.setState({
            videoNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("countryCode", this.inductionSubmissionManager.base.countryCode);
        formData.append("videoType", this.videoTypeId.toString());

        Axios.post(ENDPOINTS.api.getVideo, formData)
            .then((r) => {
                let state = {
                    videoNetworkInFlight : false
                };

                let resp = API.parse(r);

                const country_code = this.inductionSubmissionManager.base.countryCode.toLowerCase();
                const base_url = 'https://dvnxx49vqp62u.cloudfront.net';
                const audio_urls = {
                    'en': null,
                    'gb': null,
                    'us': null,
                    'es': null,
                    'de': null,
                    'pl': null,
                    'ro': null,
                    'ru': null
                };
                const video_urls = {
                    'en': `${base_url}/TSL-VIDEO-COMMON-ENGLISH.mp4`,
                    'us': `${base_url}/TSL-VIDEO-COMMON-ENGLISH-US.mp4`,
                    'es': `${base_url}/TSL-VIDEO-COMMON-SPANISH.mp4`,
                    'de': `${base_url}/TSL-VIDEO-COMMON-GERMAN.mp4`,
                    'pl': `${base_url}/TSL-VIDEO-COMMON-POLISH.mp4`,
                    'ro': `${base_url}/TSL-VIDEO-COMMON-ROMANIAN.mp4`,
                    'ru': `${base_url}/TSL-VIDEO-COMMON-RUSSIAN.mp4`
                };

                let video_url;
                video_url = video_urls[country_code] || video_urls.en;

                if (resp.success) {
                    state.videoUri = video_url;
                    state.videoDuration = resp.data.durationMinutes;
                    state.audioUri = audio_urls[country_code];
                } else {
                    this.moveToNextScreen();
                }

                this.setState(state);
            })
            .catch((e) => {
               this.moveToNextScreen();
            });
    }

    getInductionVideoConfigFromNetwork = () => {
        this.setState({
            configNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.api.getInductionVideoConfig)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let activeState = true;
                    for (let i = 0; i < resp.data.length; i++) {
                        if (parseInt(resp.data[i].inductionVideoTypeId) === parseInt(this.videoTypeId)) {
                            if (resp.data[i].countryCode.toUpperCase() === this.inductionSubmissionManager.base.countryCode.toUpperCase()) {
                                activeState = parseInt(resp.data[i].active) === 1;
                            }
                        }
                    }

                    if (activeState) {
                        this.getVideoInfoFromNetwork();
                    } else {
                        this.moveToNextScreen();
                    }

                    this.setState({
                        configNetworkInFlight : false
                    });
                } else {
                    this.getVideoInfoFromNetwork();

                    this.setState({
                        configNetworkInFlight : false
                    });
                }
            })
            .catch(e => {
                console.log(e);

                this.getVideoInfoFromNetwork();
            });
    }

    showVideo(state) {
        this.setState({
            showVideo : state,
            videoPlaying : true,
            videoFullscreen : false
        });
    }

    playPause() {
        if (!this.state.videoPlayerIsReady || (this.state.audioUri !== null && !this.state.audioPlayerIsReady)) {
            // If the players are not ready or are buffering, do not allow manual progress
            return;
        }

        let playing = false;
        if (this.state.videoPlaying) {
            this.videoPlayer.current.pause();
        } else {
            this.videoPlayer.current.play();
            playing = true;
        }

        this.setState({
            videoPlaying : playing
        });
    }

    fullscreen() {
        if (!this.state.videoFullscreen) {
            // Request fullscreen, hope it gets fulfilled because not all browsers return a promise
            if (this.videoContainer.current.requestFullscreen) {
                this.videoContainer.current.requestFullscreen();
            } else if (this.videoContainer.current.mozRequestFullScreen) {
                this.videoContainer.current.mozRequestFullScreen();
            } else if (this.videoContainer.current.webkitRequestFullscreen) {
                this.videoContainer.current.webkitRequestFullscreen();
            }
        } else {
            // Cancel fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }

        let isFullscreen = !document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement;
        this.setState({
            videoFullscreen : isFullscreen
        });
    }

    controlVideoControlVisibility(visible) {
        clearTimeout(this.videoControlsTimeout);

        let opacity = 0;
        if (visible) {
            opacity = 1;
            this.videoControlsTimeout = setTimeout(() => {
                this.controlVideoControlVisibility(false);
            }, 4000);
        }

        this.setState({
            videoControlsOpacity : opacity
        });
    }

    render() {
        let videoEnabledKey = API.INDUCTION_CONFIG.INDUCTION_VIDEO_ACTIVE;
        if (this.videoTypeId === INDUCTION_VIDEO_TYPES.COVID) {
            videoEnabledKey = API.INDUCTION_CONFIG.COVID_VIDEO_ACTIVE;
        }

        let videoEnabled = parseInt(this.getInductionConfig(videoEnabledKey, 1)) === 1;
        if (!videoEnabled) {
            return []; // Do not show anything, await navigation
        }

        let videoPlayerModalExtraStyle = "";
        let videoPlayPauseIcon = (this.state.videoPlaying) ? pauseIcon : playIcon;
        let videoFullscreenIcon = (this.state.videoFullscreen) ? fullscreenExitIcon : fullscreenIcon;
        let videoElement = [];
        let audioSource = [];
        if (this.state.showVideo) {
            videoPlayerModalExtraStyle = " show";
            videoElement = (
                <source id="video-source" src={this.state.videoUri} type="video/mp4" />
            );

            if (this.state.audioUri !== undefined && this.state.audioUri !== null) {
                audioSource = (
                    <source src={this.state.audioUri} type="audio/mp3" />
                );
            }
        }

        let contentArea = [];
        if (this.state.videoNetworkInFlight || this.state.configNetworkInFlight) {
            contentArea = (
                <div className="loading-progress">
                    <div>
                        <ProgressSpinner />
                    </div>
                    <div>{this.getString("common.pleaseWait")}</div>
                </div>
            )
        } else {
            contentArea = (
                <div>
                    <div className="row">
                        <div className="col-12 top-message">
                            {this.getString(this.state.infoStringRes, {video_duration: this.getString("inductionVideo.minutes", {duration: this.state.videoDuration})}).split("\n").map((line, i) => (
                                <p key={i}>{line}</p>
                            ))}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 bottom-button">
                            <span className="btn btn-success full-button" onClick={this.showVideo}>{this.getString("common.continue")}</span>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="container induction-video-screen">
                <CommonHeader/>

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-3 col-lg-4"/>
                    <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="screen-title">{this.getString(this.state.titleStringRes)}</div>
                            </div>
                        </div>

                        {contentArea}
                    </div>
                </div>

                <div className={"modal fade dark" + videoPlayerModalExtraStyle}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="video-container" ref={this.videoContainer}>
                                    <video
                                        className="video-player"
                                        muted={false}
                                        loop={false}
                                        autoPlay={false}
                                        controls={false}
                                        ref={this.videoPlayer}
                                        playsInline
                                        preload="auto"
                                    >
                                        {videoElement}
                                    </video>
                                    <audio autoPlay={false} controls={false} ref={this.audioPlayer}>
                                        {audioSource}
                                    </audio>
                                    <div className="video-controls" style={{opacity : this.state.videoControlsOpacity}} onMouseMove={() => this.controlVideoControlVisibility(true)} onMouseLeave={() => this.controlVideoControlVisibility(false)}>
                                        <div className="spacer" />
                                        <div className="video-control" onClick={this.playPause}>
                                            <div className="icon"  style={{ backgroundImage : "url(" + videoPlayPauseIcon + ")" }} />
                                        </div>
                                        <div className="video-control" onClick={this.fullscreen}>
                                            <div className="icon" style={{ backgroundImage : "url(" + videoFullscreenIcon + ")" }} />
                                        </div>
                                        <div className="spacer" />
                                    </div>
                                    <div className="video-position">
                                        <div className="video-position-bar" style={ {width : ((this.state.videoPlaybackPosition / this.state.videoPlaybackDuration) * 100) + "%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InductionVideoIntro;

