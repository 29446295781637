class InductionSubmissionManager {
    static instance = null;

    inductionUserId = null;
    inductionNumber = null;
    successfullySubmitted = false;

    base = {
        countryCode : null,
        fullName : null,
        companyName : null,
        occupation : null,
        emailAddress : null,
        contactNumber : null,
        homeAddressLine1 : null,
        homeAddressLine2 : null,
        homeAddressCity : null,
        homeAddressCounty : null,
        homeAddressPostcode : null,
        dateOfBirth : null,
        nextOfKinName : null,
        nextOfKinAddressLine1 : null,
        nextOfKinAddressLine2 : null,
        nextOfKinAddressCity : null,
        nextOfKinAddressCounty : null,
        nextOfKinAddressPostcode : null,
        nextOfKinContactNumber : null,
        medicalCondition : null,
        medicalConditionText : null,
        declarationName : null,
        RAMSName : null,
        GDPRName : null
    }

    photos = {
        currentPhoto : null,
        currentPhotoId : null
    }

    competency = [];

    questionnaireScore = 0;
    questionnaire = [];

    static getInstance() {
        if (InductionSubmissionManager.instance == null) {
            InductionSubmissionManager.instance = new InductionSubmissionManager();
            InductionSubmissionManager.load();
        }

        return InductionSubmissionManager.instance;
    }

    static save() {
        if (InductionSubmissionManager.instance != null) {
            let thisJson = JSON.stringify(InductionSubmissionManager.instance);
            localStorage.setItem("inductionSubmissionManagerState", thisJson);
        }
    }

    static load() {
        let inductionSubmissionManagerState = localStorage.getItem("inductionSubmissionManagerState");
        if (inductionSubmissionManagerState != null) {
            InductionSubmissionManager.instance = JSON.parse(inductionSubmissionManagerState);
        }
    }

    static reset() {
        InductionSubmissionManager.instance = new InductionSubmissionManager();
    }

}

export default InductionSubmissionManager;