import React from "react";
import Axios from "axios";

import BaseComponent from "../BaseComponent";
import InductionSubmissionManager from "../../forms/InductionSubmissionManager";
import CommonHeader from "../common/CommonHeader";
import ProgressSpinner from "../common/ProgressSpinner";

import { Locale } from "../../locale/LocaleManager";
import { API, ENDPOINTS } from "../../networking/API";

import "../../assets/tsl/css/induction-finish.css";

class InductionFinish extends BaseComponent {

    inductionSubmissionManager = InductionSubmissionManager.getInstance();

    constructor(props, context) {
        super(props, context);

        this.initState({
            inductionNumber : null,
            inductionCompletionNetworkInFlight : false,
            inductionCompletionError : null
        });

        this.signalInductionCompletionOverNetwork = this.signalInductionCompletionOverNetwork.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.signalInductionCompletionOverNetwork();
    }

    completeInduction() {
        InductionSubmissionManager.reset();
        InductionSubmissionManager.save();

        window.location.href = "/";
    }

    signalInductionCompletionOverNetwork() {
        this.setState({
            inductionCompletionNetworkInFlight : true,
            inductionCompletionError : null
        })

        let userLocale = localStorage.getItem("tslLocale");
        let locale = "en";
        if (userLocale != null) {
            if (userLocale === Locale.GERMAN) {
                locale = "de";
            }
        }

        let formData = new FormData();
        formData.append("inductionUserId", this.inductionSubmissionManager.inductionUserId);
        formData.append("locale", locale);

        Axios.post(ENDPOINTS.api.completeInduction, formData)
            .then((r) => {
                let inductionNumber = null;
                let inductionCompleteError = null;

                let resp = API.parse(r);
                if (resp.success) {
                    inductionNumber = resp.data.inductionNumber;
                } else {
                    console.log(resp.error);
                    inductionCompleteError = resp.error;
                }

                this.setState({
                    inductionCompletionNetworkInFlight : false,
                    inductionNumber : inductionNumber,
                    inductionCompletionError : inductionCompleteError
                });
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    inductionCompletionNetworkInFlight : false,
                    inductionCompletionError : "An unknown error has occurred. Please try again."
                });
            });
    }

    render() {
        let bodyContent = (
            <div className="row">
                <div className="col-12 text-center">
                    <p>
                        <ProgressSpinner />
                    </p>
                    <p>Please wait, confirming Induction...</p>
                </div>
            </div>
        );
        if (!this.state.inductionCompletionNetworkInFlight) {
            if (this.state.inductionNumber != null) {
                bodyContent = (
                    <div>
                        <div className="row">
                            <div className="hidden-xs col-sm-1 col-md-3 col-lg-4"/>
                            <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="screen-title">{this.getString("finish.title")}</div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="screen-message">
                                            <p>{this.getString("finish.numberAbove")}</p>
                                            <p className="induction-number">{this.state.inductionNumber}</p>
                                            <p>{this.getString("finish.numberBelow")}</p>
                                            {
                                                this.getString("finish.message").split("\n").map((line, i) => (
                                                    <p key={i}>{line}</p>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="bottom-button">
                                            <span className="btn btn-success full-button" onClick={this.completeInduction}>{this.getString("common.finish")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                bodyContent = (
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3>An error has occurred.</h3>
                            <p>{(this.state.inductionCompletionError != null) ? this.state.inductionCompletionError : "An unknown error has occurred. [1]"}</p>
                            <p><span className="btn btn-primary" onClick={this.signalInductionCompletionOverNetwork}>Try Again</span></p>
                        </div>
                    </div>
                )
            }
        }


        return (
            <div className="container induction-finish-screen">
                <CommonHeader />

                {bodyContent}
            </div>
        );
    }

}

export default InductionFinish;
