import React from "react";
import BaseComponent from "../BaseComponent";

import "../../assets/tsl/css/induction-country-selection.css";

import flag_gb from "../../assets/tsl/img/flag_gb.png";
import flag_us from "../../assets/tsl/img/flag_us.png";
import flag_de from "../../assets/tsl/img/flag_de.png";
import flag_pl from "../../assets/tsl/img/flag_pl.png";
import flag_ru from "../../assets/tsl/img/flag_ru.png";
import flag_ro from "../../assets/tsl/img/flag_ro.png";
import CommonHeader from "../common/CommonHeader";
import InductionSubmissionManager from "../../forms/InductionSubmissionManager";
import { Locale } from "../../locale/LocaleManager";

class InductionCountrySelection extends BaseComponent {

    countries = [
        {
            countryCode : "GB",
            label : this.getString("country.unitedKingdom"),
            image : flag_gb,
            locale : Locale.ENGLISH_GB,
            show : true
        },
        {
            countryCode: "US",
            label : this.getString("country.unitedStates"),
            image : flag_us,
            locale : Locale.ENGLISH_US,
            show : process.env.REACT_APP_CUSTOMER !== "castlemore"
        },
        {
            countryCode : "DE",
            label : this.getString("country.germany"),
            image : flag_de,
            locale : Locale.GERMAN,
            show : process.env.REACT_APP_CUSTOMER !== "castlemore"
        },
        {
            countryCode : "PL",
            label : this.getString("country.poland"),
            image : flag_pl,
            locale : Locale.POLISH,
            show : process.env.REACT_APP_CUSTOMER !== "castlemore"
        },
        {
            countryCode : "RU",
            label : this.getString("country.russia"),
            image : flag_ru,
            locale : Locale.RUSSIAN,
            show : process.env.REACT_APP_CUSTOMER !== "castlemore"
        },
        {
            countryCode : "RO",
            label : this.getString("country.romania"),
            image : flag_ro,
            locale : Locale.ROMANIAN,
            show : process.env.REACT_APP_CUSTOMER !== "castlemore"
        },
    ];

    languages = [
        {
            title : "--",
            locale : null
        },
        {
            title : this.getString("language.english"),
            locale: Locale.ENGLISH_GB
        },
        {
            title : this.getString("language.german"),
            locale: Locale.GERMAN
        },
        {
            title : this.getString("language.polish"),
            locale : Locale.POLISH
        },
        {
            title : this.getString("language.russian"),
            locale : Locale.RUSSIAN
        },
        {
            title : this.getString("language.romanian"),
            locale : Locale.ROMANIAN
        }
    ];

    inductionManager = null;

    constructor(props, context) {
        super(props, context);

        this.inductionManager = InductionSubmissionManager.getInstance();
    }

    selectCountry(countryCode, locale) {
        // Reset form and prepare for new submission
        InductionSubmissionManager.reset();
        this.inductionManager = InductionSubmissionManager.getInstance();

        this.inductionManager.base.countryCode = countryCode;

        localStorage.setItem("tslLocale", locale);

        InductionSubmissionManager.save();

        window.location.href = "/induction/start";
    }

    selectLanguage(e) {
        if (e.target.value != null) {
            localStorage.setItem("tslLocale", e.target.value);
            window.location.reload();
        }
    }

    render() {
        let countries = [];

        this.countries.forEach((country, i) => {
            if (country.show) {
                countries.push(
                    <div key={i} onClick={() => this.selectCountry(country.countryCode, country.locale)}>
                        <div className="flag-item" style={{backgroundImage: "url(" + country.image + ")"}}/>
                        <div className="label">{country.label}</div>
                    </div>
                );
            }
        });

        return (
            <div className="container country-selection-screen">
                <CommonHeader />

                <div className="row">
                    <div className="col-12">
                        <div className="flag-label">{this.getString("countrySelection.selectLanguage")}</div>
                        <div className="flag-label">{this.getString("countrySelection.selectSubtitle")}</div>
                    </div>
                </div>

                <div className="row">
                    <div className="hidden-xs col-sm-1 col-md-2 col-lg-4" />
                    <div className="col-12 col-sm-10 col-md-8 col-lg-4 flag-container">
                        {countries}
                    </div>
                </div>

            </div>
        );
    }
}

export default InductionCountrySelection;