
export const Locale = {
    ENGLISH_GB : "en-GB",
    ENGLISH_US : "en-US",
    GERMAN : "de-DE",
    POLISH : "pl-PL",
    RUSSIAN : "ru-RU",
    ROMANIAN : "ro-RO"
};

class LocaleManager {

    static instance = null;

    currentLocale = Locale.ENGLISH_GB;
    supportedLocales = [Locale.ENGLISH_GB, Locale.ENGLISH_US, Locale.GERMAN,Locale.POLISH,Locale.RUSSIAN,Locale.ROMANIAN];
    loadedStrings = {};

    static getInstance() {
        if (LocaleManager.instance == null) {
            LocaleManager.instance = new LocaleManager();
            LocaleManager.instance.loadStrings();
        }

        return this.instance;
    }

    setLocale(locale) {
        if (this.isLocaleSupported(locale)) {
            this.currentLocale = locale;
            this.loadStrings();
        }
    }

    isLocaleSupported(locale) {
        let out = false;
        this.supportedLocales.forEach((l) => {
            if (locale === l) {
                out = true;
                return false;
            }
        });

        return out;
    }

    loadStrings(locale) {
        if (locale === undefined) {
            locale = this.currentLocale;
        }

        let localeFix = locale.replace("-", "");

        if (Strings[localeFix] !== undefined) {
            this.loadedStrings = Strings[localeFix];
        }
    }

    getStrings() {
        return this.loadedStrings;
    }

    getString(keyName, replaceParams) {
        let out = "";

        let keyNameParts = keyName.split(".");
        let section = keyNameParts[0];
        let stringName = keyNameParts[1];

        if (this.loadedStrings[section] !== undefined) {
            if (this.loadedStrings[section][stringName] !== undefined) {
                out = this.loadedStrings[section][stringName];

                // Manipulate the Replace Params to handle multi-customer localisation
                if (replaceParams === undefined) {
                    replaceParams = {};
                }

                replaceParams.EP_BRANDNAME_SHORT = process.env.REACT_APP_BRAND_NAME_SHORT;
                replaceParams.EP_BRANDNAME_LONG = process.env.REACT_APP_BRAND_NAME_FULL;

                let keys = Object.keys(replaceParams);

                if (keys !== undefined) {
                    for (let i = 0; i < keys.length; i++) {
                        let key = keys[i];
                        let value = replaceParams[key];

                        out = this.replaceAll(out, "{" + key + "}", value);
                    }
                }
            }
        }

        return out;
    }

    replaceAll(haystack, needle, replace) {
        return haystack.replace(new RegExp(needle, "g"), replace);
    }
}

export default LocaleManager;

export const Strings = {
    enGB : {
        common : {
            continue : "Continue",
            edit : "Edit",
            pleaseWait : "Please Wait",
            next : "Next",
            previous : "Previous",
            finish : "Finish"
        },
        country : {
            unitedKingdom : "United Kingdom (GB)",
            unitedStates : "United States of America (US)",
            germany : "Germany (DE)",
            russia : "Russia",
            poland : "Poland",
            romania : "Romania"
        },
        language : {
            select : "Change Language",
            english : "English",
            german : "German",
            russian : "Russian",
            polish : "Polish",
            romanian : "Romanian"
        },
        countrySelection : {
            selectCountry : "Select a county",
            selectLanguage : "Select a Language",
            selectSubtitle : "This selection will determine the spoken language of the induction videos and questionnaire."
        },
        inductionIntro : {
            title : "Welcome to your {EP_BRANDNAME_SHORT} online Induction\nThis Induction process should take no more than 1 hour to complete and will be valid for 4 months across all {EP_BRANDNAME_SHORT} sites in your selected country.\nBefore continuing please ensure your device has sufficient battery life and a strong WIFI or 4G signal."
        },
        inductionSignature : {
            gdprTitle : "GDPR Consent",
            gdprMessage : "All data provided will be held on {EP_BRANDNAME_SHORT}’s secure server for the purpose of managing inductions across {EP_BRANDNAME_SHORT}’s live construction projects in your selected country.\nYour personal data will not be shared with any third parties and you will be able to freely review or destroy your data upon written request to {EP_BRANDNAME_SHORT}.\nI consent for {EP_BRANDNAME_SHORT} to use my data in the manner as detailed above.",
            inductionTitle : "Induction Declaration",
            inductionMessage : "I fully understand my responsibilities regarding the Health, Safety and Welfare of myself & others whom my work may affect, and to {EP_BRANDNAME_SHORT} as outlined in {EP_BRANDNAME_SHORT} Safety Induction. I will abide by all site rules, permit requirements, relevant risk assessment and method statements that are issued by my employer or the Principal Contractor\nI agree with the above statement",
            ramsTitle : "Acceptance of RAMS",
            ramsMessage : "By signing below, I confirm that I have read and fully understood {company_name}'s method statement, risk assessments and associated documentation.\nI agree to comply with the safe methods of work as detailed in their entirety and will not at any time diverge from the proposed safe methods of work.",
            agreement : "I agree with the above statement. Print Name and check box:"
        },
        inductionPhoto : {
            title : "Current Photo",
            takePhoto : "Take Photo",
            photoHint : "Please provide a recent photo of yourself",
            photoNotice : "This photo will be used by {EP_BRANDNAME_SHORT} appointed Security personnel for security identification purposes.\nOnly JPEG, PNG or GIF images allowed."
        },
        inductionUserDetails : {
            title : "Register Details",
            fullName : "Full Name",
            company : "Company",
            trade : "Trade/Occupation",
            email : "Email Address",
            emailVerify : "Verify Email Address",
            emailMismatchError : "Email Addresses do not match",
            contactNumber : "Contact Number",
            homeAddressLine1 : "Home Address Line 1",
            homeAddressLine2 : "Home Address Line 2",
            homeAddressCity : "Town/City",
            homeAddressCounty : "County",
            homeAddressPostcode : "Postcode",
            dateOfBirth : "Date of Birth",
            age : "Age (if under 18)",
            nextOfKinName : "Next of Kin Name",
            nextOfKinSame : "Same address as operative",
            medicalIntro : "Do you suffer from any medical conditions/allergies which may affect, or be affected by your work?\n(e.g. visual or hearing impairment)",
            medicalLabel : "If yes, please specify below",
            medicalOptOut : "If no, please tick the box"
        },
        competency : {
            title : "Proof of Competency",
            subheading : "Please provide details and photos of your valid training cards or certificates.\nSimply select 'Add'\nOnly JPEG, PNG or GIF images are allowed.",
            frontOfCard : "Front",
            backOfCard : "Back",
            updatePhoto : "Update Photo",
            cardType : "Card Type",
            idNumber : "ID Number",
            expiryDate : "Expiry Date",
            expires : "Expires: {date}",
            expiryHint : "(CSCS cards, use the last day of the expiry month)"
        },
        inductionVideo : {
            introTitle : "Induction Video",
            introInfo : "This video forms a key part of your induction, please ensure you pay full attention.\nThe video duration is {video_duration}, please ensure your device has sufficient battery life and a strong WiFi or 4G signal.\nThere will be a multiple choice questionnaire that follows the video.\nYou will be able to pause the video, it is recommended that you take notes as you progress.\n\nThis video is being streamed, please ensure you are on a stable internet connection. It may take a few minutes for the video to be fully downloaded, however you will be able to play while the video downloads.",
            covidTitle : "COVID-19",
            covidInfo : "The next page will take you to the {EP_BRANDNAME_SHORT} COVID-19 guidance video.\nThis video forms a key part of your induction, please ensure to pay full attention.\nYou will be required to comply with the video contents at all times whilst on site.\nTogether we help can reduce the impact of COVID-19.\nThank you.\n\nThis video is being streamed, please ensure you are on a stable internet connection. It may take a few minutes for the video to be fully downloaded, however you will be able to play while the video downloads.",
            minutes : "{duration} minutes"
        },
        questionnaire : {
            title : "Induction Questionnaire",
            message : "The {EP_BRANDNAME_SHORT} questionnaire consists of 15 questions, a pass mark of 13 must be achieved to progress.\nYou will have 2 attempts to complete the questionnaire.\nAfter 2 failed attempts you will be required to re-watch the {EP_BRANDNAME_SHORT} induction video.\nSimply select the box of your chosen answer and press continue.",
            questionNumber : "{position} of {total}",
            resultCongratulations : "CONGRATULATIONS",
            resultCongratsSubtitle : "you have passed the induction questionnaire.",
            resultYourScore : "Your score is:",
            resultScore : "{correct}/{total}",
            resultFooter : "Please select continue to watch to the COIVD-19 guidance video.",
            resultFailure1 : "Unfortunately you have not achieved the pass mark required to complete this induction.\nYou will now be redirected to the start of the questionnaire for a second attempt.\nShould you fail this second attempt you will be required to re-watch the {EP_BRANDNAME_SHORT} induction video.\nWhen you are ready, please select continue to start the questionnaire.",
            resultFailure2 : "Unfortunately you have not achieved the pass mark required to complete this induction\nAfter 2 failed attempts you are now required to re-watch the {EP_BRANDNAME_SHORT} induction video.\nRemember to take notes and give the video your full attention.\nWhen you are ready, please select continue to begin the video.",
            question1 : "You must have proof of what if you are intending to wear a dust / face mask?",
            question1a : "Induction",
            question1b : "Face fit Test Certificate",
            question1c : "Last Medical assessment certificate",
            question1d : "A method statement",
            question2 : "If you are using MEWPS as part of your task, what must be in place before the commencement of works?",
            question2a : "Suitable barriers",
            question2b : "Clear warning signs",
            question2c : "Rescue Plan",
            question2d : "All of the above",
            question3 : "Before commencement of works, what should be completed with your Supervisor, on a daily basis?",
            question3a : "Daily plant inspection report",
            question3b : "The project induction",
            question3c : "The Take 5 Risk Assessment Booklet",
            question3d : "Safety Quality Report (SQR)",
            question4 : "Before entering a scaffold structure, what should you check?",
            question4a : "Wind speed",
            question4b : "Your Permit to Work",
            question4c : "That a valid scaffold inspection tag is clearly displayed",
            question4d : "Time of the next fire drill",
            question5 : "The minimum PPE to be worn on this project at all times is?",
            question5a : "Hard hat, protective trousers, face visor & gloves",
            question5b : "Hi Viz vest / jacket, Safety glasses, hard hat, Safety shoes",
            question5c : "Steel toe & midsole safety boots, hard hat, Hi visibility vest / jacket, safety glasses and suitable gloves",
            question5d : "Safety goggles, water proof jacket, hard hat & wellingtons",
            question6 : "What must you do if you observe poor workmanship or damage to building products / components?",
            question6a : "Complete a Safety Observation Report using the {EP_BRANDNAME_SHORT} SQR App",
            question6b : "Review your method statement",
            question6c : "Complete a Take 5 Risk Assessment Booklet",
            question6d : "Complete a Quality Observation Report using the {EP_BRANDNAME_SHORT} SQR App",
            question7 : "Which of the following Permits are valid for one day only?",
            question7a : "Work at Height, General & Excavation Permits",
            question7b : "General, Service Isolation and Excavation Permits",
            question7c : "Hot work, Excavation & General Permits",
            question7d : "Excavation, Service Isolation and Hot work Permits",
            question8 : "What remains the biggest cause of fatal and major injuries in the construction industry?",
            question8a : "Crushing by Heavy Plant and equipment",
            question8b : "Collapse of excavations",
            question8c : "Falls from height",
            question8d : "Electrocution",
            question9 : "If working around skylights, what must you ensure?",
            question9a : "They are clearly signed and painted yellow for visibility",
            question9b : "They have guard rails around them or are boarded over",
            question9c : "You wear a harness",
            question9d : "You have a General Permit to Work",
            question10 : "In which of the following areas is smoking permitted?",
            question10a : "Anywhere within the Welfare areas",
            question10b : "Away from gas cylinders",
            question10c : "In the cabs of plant on site",
            question10d : "Only in the designated smoking areas",
            question11 : "What are {EP_BRANDNAME_SHORT}’s Safety Goals for this Project?",
            question11a : "Accident & Injury Free, with low harm to the environment",
            question11b : "As few accidents as possible and try not to harm the environment",
            question11c : "No RIDDOR accidents",
            question11d : "Zero accidents & Incidents, Zero harm to the environment and a pleasant working environment",
            question12 : "Poor housekeeping creates what hazards?\n",
            question12a : "Slippery floors",
            question12b : "Slips, Trips and Fire Hazards",
            question12c : "An unhealthy workplace",
            question12d : "Fire Hazards",
            question13 : "When must you sign in and out of the project?",
            question13a : "At the start and end of the day",
            question13b : "At the start and end of the week",
            question13c : "Every time you enter and leave the project",
            question13d : "When a fire alarm sounds",
            question14 : "Who should take ownership and responsibility to ensure the Quality of works and getting things right the first time?",
            question14a : "The {EP_BRANDNAME_SHORT} HSQE Department",
            question14b : "The {EP_BRANDNAME_SHORT} Quality Manager",
            question14c : "Everyone on site",
            question14d : "The Project Manager",
            question15 : "First Aid Kits, Burns Kits and Defibrillator are located in?",
            question15a : "The mess room",
            question15b : "The {EP_BRANDNAME_SHORT} Project Office",
            question15c : "Throughout the Welfare areas",
            question15d : "The meeting room"
        },
        submission : {
            title : "Please wait",
            message : "Your induction is being uploaded. Please wait.",
            progress : "{percentage}%"
        },
        finish : {
            title : "Well done, you have successfully completed your {EP_BRANDNAME_SHORT} Site Induction.",
            numberAbove : "Your induction number is",
            numberBelow : "This induction is valid for 4 Months.",
            message : "You will now be sent a confirmation email to the email address you’ve provided.\nPlease ensure to keep this email safe and readily available to show the {EP_BRANDNAME_SHORT} Site Team when you first access each new site."
        }
    },
    enUS : {
        common : {
            continue : "Continue",
            edit : "Edit",
            pleaseWait : "Please Wait",
            next : "Next",
            previous : "Previous",
            finish : "Finish"
        },
        country : {
            unitedKingdom : "United Kingdom (GB)",
            unitedStates : "United States of America (US)",
            germany : "Germany (DE)",
            russia : "Russia",
            poland : "Poland",
            romania : "Romania"
        },
        language : {
            select : "Change Language",
            english : "English",
            german : "German",
            russian : "Russian",
            polish : "Polish",
            romanian : "Romanian"
        },
        countrySelection : {
            selectCountry : "Select a county",
            selectLanguage : "Select a Language",
            selectSubtitle : "This selection will determine the spoken language of the orientation videos and questionnaire."
        },
        inductionIntro : {
            title : "Welcome to your {EP_BRANDNAME_SHORT} online Orientation\nThis Orientation process should take no more than 1 hour to complete and will be valid for 4 months across all {EP_BRANDNAME_SHORT} sites in your selected country.\nBefore continuing please ensure your device has sufficient battery life and a strong WIFI or 4G signal."
        },
        inductionSignature : {
            gdprTitle : "GDPR Consent",
            gdprMessage : "All data provided will be held on {EP_BRANDNAME_SHORT}’s secure server for the purpose of managing orientations across {EP_BRANDNAME_SHORT}’s live construction projects in your selected country.\nYour personal data will not be shared with any third parties and you will be able to freely review or destroy your data upon written request to {EP_BRANDNAME_SHORT}.\nI consent for {EP_BRANDNAME_SHORT} to use my data in the manner as detailed above.",
            inductionTitle : "Orientation Declaration",
            inductionMessage : "I fully understand my responsibilities regarding the Health, Safety and Welfare of myself & others whom my work may affect, and to {EP_BRANDNAME_SHORT} as outlined in {EP_BRANDNAME_SHORT} Safety Orientation. I will abide by all site rules, permit requirements, relevant risk assessment and method statements that are issued by my employer or the Principal Contractor\nI agree with the above statement",
            ramsTitle : "Acceptance of RAMS",
            ramsMessage : "By signing below, I confirm that I have read and fully understood {company_name}'s method statement, risk assessments and associated documentation.\nI agree to comply with the safe methods of work as detailed in their entirety and will not at any time diverge from the proposed safe methods of work.",
            agreement : "I agree with the above statement. Print Name and check box:"
        },
        inductionPhoto : {
            title : "Current Photo",
            takePhoto : "Take Photo",
            photoHint : "Please provide a recent photo of yourself",
            photoNotice : "This photo will be used by {EP_BRANDNAME_SHORT} appointed Security personnel for security identification purposes.\nOnly JPEG, PNG or GIF images allowed."
        },
        inductionUserDetails : {
            title : "Register Details",
            fullName : "Full Name",
            company : "Company",
            trade : "Trade/Occupation",
            email : "Email Address",
            emailVerify : "Verify Email Address",
            emailMismatchError : "Email Addresses do not match",
            contactNumber : "Contact Number",
            homeAddressLine1 : "Home Address Line 1",
            homeAddressLine2 : "Home Address Line 2",
            homeAddressCity : "Town/City",
            homeAddressCounty : "County",
            homeAddressPostcode : "Postcode",
            dateOfBirth : "Date of Birth",
            age : "Age (if under 18)",
            nextOfKinName : "Next of Kin Name",
            nextOfKinSame : "Same address as operative",
            medicalIntro : "Do you suffer from any medical conditions/allergies which may affect, or be affected by your work?\n(e.g. visual or hearing impairment)",
            medicalLabel : "If yes, please specify below",
            medicalOptOut : "If no, please tick the box"
        },
        competency : {
            title : "Proof of Competency",
            subheading : "Please provide details and photos of your valid training cards or certificates.\nSimply select 'Add'\nOnly JPEG, PNG or GIF images are allowed.",
            frontOfCard : "Front",
            backOfCard : "Back",
            updatePhoto : "Update Photo",
            cardType : "Card Type",
            idNumber : "ID Number",
            expiryDate : "Expiry Date",
            expires : "Expires: {date}",
            expiryHint : "(CSCS cards, use the last day of the expiry month)"
        },
        inductionVideo : {
            introTitle : "Orientation Video",
            introInfo : "This video forms a key part of your orientation, please ensure you pay full attention.\nThe video duration is {video_duration}, please ensure your device has sufficient battery life and a strong WiFi or 4G signal.\nThere will be a multiple choice questionnaire that follows the video.\nYou will be able to pause the video, it is recommended that you take notes as you progress.\n\nThis video is being streamed, please ensure you are on a stable internet connection. It may take a few minutes for the video to be fully downloaded, however you will be able to play while the video downloads.",
            covidTitle : "COVID-19",
            covidInfo : "The next page will take you to the {EP_BRANDNAME_SHORT} COVID-19 guidance video.\nThis video forms a key part of your orientation, please ensure to pay full attention.\nYou will be required to comply with the video contents at all times whilst on site.\nTogether we help can reduce the impact of COVID-19.\nThank you.\n\nThis video is being streamed, please ensure you are on a stable internet connection. It may take a few minutes for the video to be fully downloaded, however you will be able to play while the video downloads.",
            minutes : "{duration} minutes"
        },
        questionnaire : {
            title : "Orientation Questionnaire",
            message : "The {EP_BRANDNAME_SHORT} questionnaire consists of 15 questions, a pass mark of 13 must be achieved to progress.\nYou will have 2 attempts to complete the questionnaire.\nAfter 2 failed attempts you will be required to re-watch the {EP_BRANDNAME_SHORT} orientation video.\nSimply select the box of your chosen answer and press continue.",
            questionNumber : "{position} of {total}",
            resultCongratulations : "CONGRATULATIONS",
            resultCongratsSubtitle : "you have passed the orientation questionnaire.",
            resultYourScore : "Your score is:",
            resultScore : "{correct}/{total}",
            resultFooter : "Please select continue to watch to the COIVD-19 guidance video.",
            resultFailure1 : "Unfortunately you have not achieved the pass mark required to complete this orientation.\nYou will now be redirected to the start of the questionnaire for a second attempt.\nShould you fail this second attempt you will be required to re-watch the {EP_BRANDNAME_SHORT} orientation video.\nWhen you are ready, please select continue to start the questionnaire.",
            resultFailure2 : "Unfortunately you have not achieved the pass mark required to complete this orientation\nAfter 2 failed attempts you are now required to re-watch the {EP_BRANDNAME_SHORT} orientation video.\nRemember to take notes and give the video your full attention.\nWhen you are ready, please select continue to begin the video.",
            question1 : "You must have proof of what if you are intending to wear a dust / face mask?",
            question1a : "Orientation",
            question1b : "Face fit Test Certificate",
            question1c : "Last Medical assessment certificate",
            question1d : "A method statement",
            question2 : "If you are using MEWPS as part of your task, what must be in place before the commencement of works?",
            question2a : "Suitable barriers",
            question2b : "Clear warning signs",
            question2c : "Rescue Plan",
            question2d : "All of the above",
            question3 : "Before commencement of works, what should be completed with your Supervisor, on a daily basis?",
            question3a : "Daily plant inspection report",
            question3b : "The project orientation",
            question3c : "The Take 5 Risk Assessment Booklet",
            question3d : "Safety Quality Report (SQR)",
            question4 : "Before entering a scaffold structure, what should you check?",
            question4a : "Wind speed",
            question4b : "Your Permit to Work",
            question4c : "That a valid scaffold inspection tag is clearly displayed",
            question4d : "Time of the next fire drill",
            question5 : "The minimum PPE to be worn on this project at all times is?",
            question5a : "Hard hat, protective trousers, face visor & gloves",
            question5b : "Hi Viz vest / jacket, Safety glasses, hard hat, Safety shoes",
            question5c : "Steel toe & midsole safety boots, hard hat, Hi visibility vest / jacket, safety glasses and suitable gloves",
            question5d : "Safety goggles, water proof jacket, hard hat & wellingtons",
            question6 : "What must you do if you observe poor workmanship or damage to building products / components?",
            question6a : "Complete a Safety Observation Report using the {EP_BRANDNAME_SHORT} SQR App",
            question6b : "Review your method statement",
            question6c : "Complete a Take 5 Risk Assessment Booklet",
            question6d : "Complete a Quality Observation Report using the {EP_BRANDNAME_SHORT} SQR App",
            question7 : "Which of the following Permits are valid for one day only?",
            question7a : "Work at Height, General & Excavation Permits",
            question7b : "General, Service Isolation and Excavation Permits",
            question7c : "Hot work, Excavation & General Permits",
            question7d : "Excavation, Service Isolation and Hot work Permits",
            question8 : "What remains the biggest cause of fatal and major injuries in the construction industry?",
            question8a : "Crushing by Heavy Plant and equipment",
            question8b : "Collapse of excavations",
            question8c : "Falls from height",
            question8d : "Electrocution",
            question9 : "If working around skylights, what must you ensure?",
            question9a : "They are clearly signed and painted yellow for visibility",
            question9b : "They have guard rails around them or are boarded over",
            question9c : "You wear a harness",
            question9d : "You have a General Permit to Work",
            question10 : "In which of the following areas is smoking permitted?",
            question10a : "Anywhere within the Welfare areas",
            question10b : "Away from gas cylinders",
            question10c : "In the cabs of plant on site",
            question10d : "Only in the designated smoking areas",
            question11 : "What are {EP_BRANDNAME_SHORT}’s Safety Goals for this Project?",
            question11a : "Accident & Injury Free, with low harm to the environment",
            question11b : "As few accidents as possible and try not to harm the environment",
            question11c : "No RIDDOR accidents",
            question11d : "Zero accidents & Incidents, Zero harm to the environment and a pleasant working environment",
            question12 : "Poor housekeeping creates what hazards?\n",
            question12a : "Slippery floors",
            question12b : "Slips, Trips and Fire Hazards",
            question12c : "An unhealthy workplace",
            question12d : "Fire Hazards",
            question13 : "When must you sign in and out of the project?",
            question13a : "At the start and end of the day",
            question13b : "At the start and end of the week",
            question13c : "Every time you enter and leave the project",
            question13d : "When a fire alarm sounds",
            question14 : "Who should take ownership and responsibility to ensure the Quality of works and getting things right the first time?",
            question14a : "The {EP_BRANDNAME_SHORT} HSQE Department",
            question14b : "The {EP_BRANDNAME_SHORT} Quality Manager",
            question14c : "Everyone on site",
            question14d : "The Project Manager",
            question15 : "First Aid Kits, Burns Kits and Defibrillator are located in?",
            question15a : "The mess room",
            question15b : "The {EP_BRANDNAME_SHORT} Project Office",
            question15c : "Throughout the Welfare areas",
            question15d : "The meeting room"
        },
        submission : {
            title : "Please wait",
            message : "Your orientation is being uploaded. Please wait.",
            progress : "{percentage}%"
        },
        finish : {
            title : "Well done, you have successfully completed your {EP_BRANDNAME_SHORT} Site Orientation.",
            numberAbove : "Your orientation number is",
            numberBelow : "This orientation is valid for 4 Months.",
            message : "You will now be sent a confirmation email to the email address you’ve provided.\nPlease ensure to keep this email safe and readily available to show the {EP_BRANDNAME_SHORT} Site Team when you first access each new site."
        }
    },
    deDE : {
        common : {
            continue : "Weiter",
            edit : "Ändern",
            pleaseWait : "Warten Sie mal",
            next : "Kommend",
            previous : "Bisherige",
            finish : "Beenden"
        },
        country : {
            unitedKingdom : "Vereinigtes Königreich (GB)",
            unitedStates : "Vereinigte Staaten von Amerika (US)",
            germany : "Deutschland (DE)",
            russia : "Russland",
            poland : "Polen",
            romania : "Rumänien"
        },
        language : {
            select : "Sprache ändern",
            english : "Englisch",
            german : "Deutsche",
            russian : "Russisch",
            polish : "Polieren",
            romanian : "Rumänisch"
        },
        countrySelection : {
            selectCountry : "Land auswählen",
            selectLanguage : "Wähle eine Sprache",
            selectSubtitle : ""
        },
        inductionIntro : {
            title : "Willkommen auf Ihrer {EP_BRANDNAME_SHORT}-Online-Unterweisung\nDieser Unterweisungsprozess sollte nicht länger als 1 Stunde dauern und ist 4 Monate lang an allen {EP_BRANDNAME_SHORT}-Standorten in dem von Ihnen ausgewählten Land gültig.\nBevor Sie fortfahren, stellen Sie bitte sicher, dass Ihr mobiles Gerät über eine ausreichende Akkulaufzeit und ein starkes WIFI- oder 4G-Signal verfügt."
        },
        inductionSignature : {
            gdprTitle : "GDPR (Datenschutz)-Zustimmung",
            gdprMessage : "Alle zur Verfügung gestellten Daten werden auf dem sicheren Server von {EP_BRANDNAME_SHORT} gespeichert, um Induktionen über die Live-Bauprojekte von {EP_BRANDNAME_SHORT} in dem von Ihnen ausgewählten Land zu verwalten.\nIhre persönlichen Daten werden nicht an Dritte weitergegeben, und Sie haben die Möglichkeit, Ihre Daten auf schriftliche Anfrage an {EP_BRANDNAME_SHORT} frei einzusehen oder zu vernichten.",
            inductionTitle : "Induktions-Erklärung",
            inductionMessage : "Ich bin mir meiner Verantwortung für die Gesundheit, Sicherheit und das Wohlergehen meiner selbst und anderer Personen, die von meiner Arbeit betroffen sein können, sowie gegenüber {EP_BRANDNAME_SHORT}, wie in der Sicherheitseinführung von {EP_BRANDNAME_SHORT} Ltd. dargelegt, vollkommen bewusst.\nIch werde mich an alle Baustellenvorschriften, Genehmigungsanforderungen, relevanten Risikobewertungen und Methodenerklärungen halten, die von meinem Arbeitgeber oder dem Hauptauftragnehmer ausgestellt werden.",
            ramsTitle : "Akzeptanz der Gefährdungsbeurteilungen",
            ramsMessage : "Mit meiner Unterschrift bestätige ich, dass ich Folgendes gelesen und vollständig verstanden habe Arbeitsanweisung, Risikobewertungen und zugehörige Dokumentation.\nIch erkläre mich damit einverstanden, die sicheren Arbeitsmethoden, wie sie im Einzelnen aufgeführt sind, in ihrer Gesamtheit einzuhalten und zu keiner Zeit von den vorgeschlagenen sicheren Arbeitsmethoden abzuweichen.",
            agreement : "Ich stimme der obigen Aussage zu. Namen eingeben und Haken in das Kontrollkästchen:"
        },
        inductionPhoto : {
            title : "Aktuelles Foto (einfügen)",
            takePhoto : "Foto machen",
            photoHint : "Bitte stellen Sie ein aktuelles Foto von sich zur Verfügung.",
            photoNotice : "Dieses Foto wird von dem von der {EP_BRANDNAME_SHORT} ernannten Sicherheitspersonal zur Sicherheitskennzeichnung verwendet."
        },
        inductionUserDetails : {
            title : "Registrieren Sie Ihre Angaben",
            fullName : "Vollständiger Name",
            company : "Unternehmen (Firma)",
            trade : "Berufsbezeichnung",
            email : "E-Mailadresse",
            emailVerify : "Email Adresse bestätigen",
            emailMismatchError : "E-Mail-Adressen stimmen nicht überein",
            contactNumber : "Kontakt-Nummer Ihre Telefonnummer",
            homeAddressLine1 : "Straße",
            homeAddressLine2 : "Zusatzanschrift",
            homeAddressCity : "Ort",
            homeAddressCounty : "Bundesland",
            homeAddressPostcode : "Postleitzahl",
            dateOfBirth : "Geburtsdatum",
            age : "Alter wenn unter 18 Jahren",
            nextOfKinName : "Nächster Angehöriger",
            nextOfKinSame : "Same address as operative",
            medicalIntro : "Leiden Sie an Krankheiten/Allergien, die Ihre Arbeit beeinträchtigen oder von ihr beeinflusst werden könnten?\n(z.B. Seh- oder Hörbehinderung, Diabetes)",
            medicalLabel : "Wenn ja, bitte unten angeben",
            medicalOptOut : "Wenn nein, kreuzen Sie bitte das folgende Kästchen an"
        },
        competency : {
            title : "Kompetenz Nachweis (Ausbildungsnachweise)",
            subheading : "Bitte geben Sie Einzelheiten und ein Foto Ihrer gültigen Ausbildungskarten an.\nWählen Sie einfach 'Hinzufügen'.",
            frontOfCard : "Vorderseite",
            backOfCard : "Rückseite",
            updatePhoto : "Foto hinzufügen",
            cardType : "Kartentyp",
            idNumber : "ID-Nummer",
            expiryDate : "Verfalldatum",
            expires : "Verfalldatum: {date}",
            expiryHint : "(CSCS cards, use the last day of the expiry month)" // TODO
        },
        inductionVideo : {
            introTitle : "Unterweisungs-Video",
            introInfo : "Dieses Video ist ein wichtiger Bestandteil der Unterweisung. Bitte konzentrieren sich darauf.\nDie Dauer des Videos beträgt {video_duration}. Bitte stellen Sie sicher, dass Ihr mobiles Gerät über eine ausreichende Akkulaufzeit und ein starkes WIFI- oder 4G-Signal verfügt. \nIm Anschluss an das Video wird ein Multiple-Choice-Fragebogen ausgefüllt.\nSie haben die Möglichkeit, das Video anzuhalten. Es wird empfohlen, während des Videos Notizen zu machen.",
            covidTitle : "COVID-19",
            covidInfo : "Die nächste Seite führt Sie zur {EP_BRANDNAME_SHORT}\nCOVID-19-Anleitungsvideo.\nDieses Video ist ein wichtiger Teil Ihrer Einführung, bitte achten Sie auf Ihre volle Aufmerksamkeit.\nWährend Ihres Aufenthalts vor Ort sind Sie verpflichtet, den Inhalt des Videos jederzeit einzuhalten.\nGemeinsam können wir dazu beitragen, die Auswirkungen von COVID-19 zu verringern.\nVielen Dank.\n\nDieses Video wird gestreamt. Bitte stellen Sie sicher, dass Sie über eine stabile Internetverbindung verfügen. Es kann einige Minuten dauern, bis das Video vollständig heruntergeladen ist. Sie können es jedoch abspielen, während das Video heruntergeladen wird.",
            minutes : "{duration} Minuten"
        },
        questionnaire : {
            title : "Einweisungsfragebogen",
            message : "Der {EP_BRANDNAME_SHORT}-Fragebogen besteht aus 15 Fragen, wobei eine Mindestpunktzahl von 13 erreicht werden muss, um Fortschritte zu erzielen.\nSie haben 2 Versuche, den Fragebogen auszufüllen und den Test zu bestehen.\nNach 2 fehlgeschlagenen Versuchen müssen Sie sich das {EP_BRANDNAME_SHORT}-Unterweisungsvideo erneut ansehen.\nMarkieren Sie einfach das Kästchen Ihrer gewählten Antwort und drücken Sie auf Weiter.",
            questionNumber : "{position}/{total}",
            resultCongratulations : "GLÜCKWÜNSCHE",
            resultCongratsSubtitle : "Sie haben den Unterweisungsfragebogen bestanden.",
            resultYourScore : "Ihr Ergebnis ist:",
            resultScore : "{correct}/{total}",
            resultFooter : "Bitte wählen Sie weiter, um mit dem  Video mit dem „Leitfaden COIVD-19“ fortzufahren.",
            resultFailure1 : "Leider haben Sie nicht die erforderliche Mindestpunktzahl erreicht, um diese Unterweisung erfolgreich abzuschließen.\nSie werden nun für einen zweiten Versuch an den Anfang des Fragebogens weitergeleitet.\nSollten Sie beim zweiten Versuch scheitern, müssen Sie sich das {EP_BRANDNAME_SHORT}-Einführungsvideo erneut ansehen.\nWenn Sie bereit sind, wählen Sie bitte Weiter, um den Fragebogen zu starten.",
            resultFailure2 : "Leider haben Sie nicht die erforderliche Mindestpunktzahl erreicht, um diese Einführung abzuschließen.\nNach 2 fehlgeschlagenen Versuchen müssen Sie sich nun das {EP_BRANDNAME_SHORT}-Einführungsvideo erneut ansehen.\nDenken Sie daran, Notizen zu machen und dem Video Ihre volle Aufmerksamkeit zu schenken.\nWenn Sie bereit sind, wählen Sie bitte Weiter, um das Video zu beginnen.",
            question1 : "Was müssen Sie nachweisen können wenn Sie vorhaben eine Staub-/Gesichtsmaske zu tragen?",
            question1a : "Induktion",
            question1b : "Dichtsitzprüfung der Maske (Face fit Test)",
            question1c : "Letztes ärztliches Attest",
            question1d : "Eine Arbeitsanweisung",
            question2 : "Wenn Sie mobile Hub-/Hebearbeitsbühnen für Ihre Arbeit benutzen, was muss an Ort und Stelle sein, bevor Sie mit Ihrer Arbeit beginnen?",
            question2a : "Geeignete Absperrungen",
            question2b : "Deutliche Warnschilder",
            question2c : "Rettungsplan",
            question2d : "Alle oben genannten",
            question3 : "Was sollte täglich, vor Arbeitsbeginn, mit Ihrem Vorgesetzten ausgefüllt werden?",
            question3a : "Täglicher Maschineninspektionsbericht",
            question3b : "Die Einweisung in das Projekt",
            question3c : "Die Broschüre über die 5 häufigsten Gefährdungen (Take 5 Broschüre)",
            question3d : "Berichtskarte zur Sicherheitsbeobachtung (SOR)",
            question4 : "Was sollten Sie überprüfen, bevor Sie eine Gerüstkonstruktion betreten?",
            question4a : "Die Windstärke",
            question4b : "Ihre Arbeitsgenehmigung",
            question4c : "Dass ein gültiges Gerüstprüfschild deutlich sichtbar ist",
            question4d : "Zeitpunkt der nächsten Feueralarmübung",
            question5 : "Was ist das Mindeste an persönlicher Schutzbekleidung, die Sie immer bei diesem Projekt tragen sollen?",
            question5a : "Schutzhelm, Schutzhose, Gesichtsvisier & Handschuhe",
            question5b : "Warnweste / -jacke, Schutzbrille, Schutzhelm, Sicherheitsschuhe",
            question5c : "Stiefel mit Zehenschutzkappe und durchtrittssicherer Sohle, Schutzhelm, Warnweste / Jacke, Schutzbrille und geeignete Handschuhe ",
            question5d : "Schutzbrille, wasserdichte Jacke, Schutzhelm & Gummistiefel",
            question6 : "Was müssen Sie tun, wenn Sie eine schlechte Ausführung oder Schäden an Bauprodukten / Komponenten feststellen?",
            question6a : "Füllen Sie einen Berichtsbogen zur Sicherheitsbeobachtung (Safety Observation Report Card - SOR) aus und legen Sie sie in den Briefkasten",
            question6b : "Überprüfen Sie Ihre Arbeitsanweisungen",
            question6c : "Füllen Sie eine Take 5 Risikobewertungsbroschüre aus",
            question6d : "Füllen Sie eine Qualitäts- oder Sicherheitsbeobachtungsberichtskarte mit der TSL SQR-App aus.",
            question7 : "Welche der folgenden Genehmigungen sind nur einen Tag lang gültig?",
            question7a : "Arbeiten in der Höhe, allgemein & Aushubgenehmigungen",
            question7b : "Allgemeine, Service Isolierung und Aushubgenehmigungen",
            question7c : "Heißarbeiten, Aushub und allgemeine Genehmigungen",
            question7d : "Aushub-, Service-Isolierung und Heißarbeitsgenehmigungen",
            question8 : "Was bleibt die Hauptursache für Todesfälle und schwere Verletzungen im Baugewerbe?",
            question8a : "Quetschungen durch schwere Maschinen und Geräte",
            question8b : "Einsturz von Erdarbeiten",
            question8c : "Höhenabstürze",
            question8d : "Stromschlag",
            question9 : "Was müssen Sie sicherstellen, wenn Sie um die Dachfenster, - luken herum arbeiten,?",
            question9a : "Sie sind deutlich gekennzeichnet und zur besseren Sichtbarkeit gelb lackiert",
            question9b : "Sie sind mit Geländern zu versehen oder trittsicher zu überdecken",
            question9c : "Sie müssen ein Fallgeschirr tragen",
            question9d : "Sie haben eine allgemeine Arbeitserlaubnis",
            question10 : "In welchen folgenden Bereichen ist das Rauchen erlaubt?",
            question10a : "Innerhalb der Sozialräume",
            question10b : "Weg von Gasflaschen",
            question10c : "In den Baustellencontainern vor Ort",
            question10d : "Nur in den beschilderten Raucherbereichen",
            question11 : "Was sind die Sicherheitsziele von {EP_BRANDNAME_SHORT} für dieses Projekt?",
            question11a : "Unfall- und verletzungsfrei, mit geringen Umweltschäden",
            question11b : "So wenig Unfälle wie möglich und der Versuch, die Umwelt nicht zu belasten",
            question11c : "Keine Meldepflichtigen Unfälle Berufsgenossenschaft",
            question11d : "Keine Unfälle und Vorfälle, keine Umweltschäden und ein angenehmes Betriebsklima",
            question12 : "Schlechte Haushaltsführung schafft welche Risiken?",
            question12a : "Rutschige Böden",
            question12b : "Rutsch-, Stolper- und Brandgefahren",
            question12c : "Ein unaufgeräumter Arbeitsplatz",
            question12d : "Brandquellen, -gefahren",
            question13 : "Wann müssen Sie sich am Projekt an- und abmelden?",
            question13a : "Täglich vor Arbeitsbeginn",
            question13b : "Am Anfang und Ende der Woche",
            question13c : "Bei jedem Kommen und Gehen des Projektes",
            question13d : "Wenn der Feueralarm ausgelöst wird",
            question14 : "Wer sollte sich verantwortlich zeigen, um die Qualität der Arbeiten zu gewährleisten und die Dinge beim ersten Mal richtig zu machen?",
            question14a : "Die {EP_BRANDNAME_SHORT} Abteilung für Sicherheit und Qualitätsmanagment",
            question14b : "Der {EP_BRANDNAME_SHORT} Qualitätsmanager",
            question14c : "Jeder auf der Baustelle",
            question14d : "Der Projektmanager",
            question15 : "Erste-Hilfe-Kästen, Verbrennungssets und Defibrillatoren befinden sich an folgenden Orten?",
            question15a : "Im Pausenraum (Kantine)",
            question15b : "Im {EP_BRANDNAME_SHORT} Baustellenbüro",
            question15c : "In den gesamten Sozialräumen",
            question15d : "Im Besprechungsraum"
        },
        submission : {
            title : "Warten Sie mal",
            message : "Hochladen",
            progress : "{percentage}%"
        },
        finish : {
            title : "Gut gemacht, Sie haben Ihre {EP_BRANDNAME_SHORT}-Site Induction erfolgreich abgeschlossen.",
            numberAbove : "Ihre Induktionsnummer lautet",
            numberBelow : "Diese Einweisung gilt für 4 Monate.",
            message : "Sie erhalten nun eine Bestätigungs-E-Mail an die von Ihnen angegebene E-Mail-Adresse.\nBitte vergewissern Sie sich, dass diese E-Mail sicher und leicht zugänglich aufbewahrt wird, dass Sie diese dem TSL-Baustellen-Team beim ersten Tag ihrer Ankunft vorzeigen können."
        }
    },
    ruRU : {
        common : {
            continue : "Продолжение",
            edit : "Редактировать",
            pleaseWait : "Подождите пожалуйста",
            next : "Следующий",
            previous : "Предыдущий",
            finish : "законченный"
        },
        country : {
            unitedKingdom : "Объединенное Королевство",
            unitedStates : "Соединенные Штаты Америки",
            germany : "Германия",
            russia : "Россия",
            poland : "Польша",
            romania : "Румыния"
        },
        language : {
            select : "Выберите страну",
            english : "английский",
            german : "Немецкий",
            russian : "русский",
            polish : "Польский",
            romanian : "румынский"
        },
        countrySelection : {
            selectCountry : "Выберите страну",
            selectLanguage : "Выберите язык",
            selectSubtitle : "От этого выбора будет зависеть разговорный язык вводных видеороликов и анкеты."
        },
        inductionIntro : {
            title : "Добро пожаловать на вводный инструктаж компании {EP_BRANDNAME_SHORT} в режиме онлайн\nВводный инструктаж займет не более 1 часа и будет действителен в течение 4 месяцев на всех площадках {EP_BRANDNAME_SHORT} в выбранной вами стране. \nПрежде чем продолжить, убедитесь, что ваше мобильное устройство имеет достаточный заряд батареи и уведенный прием сигнала WIFI или 4G."
        },
        inductionSignature : {
            gdprTitle : "Согласие на обработку персональных данных",
            gdprMessage : "Все предоставленные данные будут храниться на защищенном сервере {EP_BRANDNAME_SHORT} для целей проведения вводных инструктажей по всем строительным проектам {EP_BRANDNAME_SHORT} в выбранной вами стране.  Ваши личные данные не будут передаваться третьим лицам, и вы сможете свободно просматривать или уничтожать свои данные по письменному запросу в {EP_BRANDNAME_SHORT}.\nЯ даю согласие на использование компанией {EP_BRANDNAME_SHORT} моих персональных данных вышеуказанным образом.",
            inductionTitle : "Заявление о прохождении вводного инструктажа",
            inductionMessage : "Я полностью понимаю свои обязанности в отношении здоровья, безопасности и благополучия себя и других людей, на которых может повлиять моя работа, а также в отношении {EP_BRANDNAME_SHORT}, как указано в Инструкции по безопасности компании {EP_BRANDNAME_SHORT} Ltd.\n\nОбязуюсь соблюдать все правила, требования по получению разрешений, выполнять соответствующие оценки рисков и соблюдать методические указания, выданные моим работодателем или генподрядчиком.\n\nЯ согласен с вышеуказанным утверждением",
            ramsTitle : "Подтверждение понимания ППР",
            ramsMessage : "Подписывая ниже, я подтверждаю, что прочитал и полностью понял ППР оценки рисков и сопутствующую документацию.\n\nЯ согласен соблюдать безопасные методы работы в полном объеме и ни в коем случае не отступать от предложенных безопасных методов работы.",
            agreement : "Напишите свои ФИО печатными буквами и поставьте галочку:"
        },
        inductionPhoto : {
            title : "Актуальная фотография",
            takePhoto : "Добавить фотографию",
            photoHint : "Просим вас предоставить свою актуальную фотографию. ",
            photoNotice : "Эта фотография будет использоваться назначенными {EP_BRANDNAME_SHORT} сотрудниками службы безопасности для идентификации в целях безопасности. "
        },
        inductionUserDetails : {
            title : "Зарегистрируйте свои данные",
            fullName : "Полное имя",
            company : "компания",
            trade : "Профессия / Род занятий",
            email : "Электронная почта",
            emailVerify : "подтвердите адрес электронной почты",
            emailMismatchError : "Адреса электронной почты не совпадают",
            contactNumber : "Контактный номер",
            homeAddressLine1 : "Домашний адрес 1",
            homeAddressLine2 : "Домашний адрес 2",
            homeAddressCity : "Город",
            homeAddressCounty : "округ",
            homeAddressPostcode : "Почтовый индекс",
            dateOfBirth : "Дата рождения",
            age : "Возраст, если нет 18 лет",
            nextOfKinName : "Ближайшие родственники",
            nextOfKinSame : "Тот же адрес, что и оперативный",
            medicalIntro : "Страдаете ли вы какими-либо заболеваниями / аллергиями, которые могут повлиять на вашу работу?\n\n(например, нарушение зрения или слуха)",
            medicalLabel : "Если да, пожалуйста, укажите ниже",
            medicalOptOut : "Если нет, пожалуйста, отметьте галочкой ниже"
        },
        competency : {
            title : "Подтверждение компетентности",
            subheading : "Пожалуйста, предоставьте данные и фото ваших действующих сертификатов о прохождении обучения.\nПросто выберите 'Добавить'",
            frontOfCard : "Лицевая сторона сертификата",
            backOfCard : "Оборотная сторона сертификата",
            updatePhoto : "Добавить фотографию",
            cardType : "Тип сертификата",
            idNumber : "Идентификационный номер",
            expiryDate : "Срок действия",
            expires : "Срок действия: {date}",
            expiryHint : "(Карты CSCS, используйте последний день месяца истечения срока действия)"
        },
        inductionVideo : {
            introTitle : "Видеоролик вводного инструктажа",
            introInfo : "Это видео является ключевой частью вашего вводного инструктажа, пожалуйста, уделите ему полное внимание. \n\nПродолжительность видео составляет {video_duration}, пожалуйста, убедитесь, что ваше мобильное устройство имеет достаточный заряд батареи и уверенный прием сигнала WIFI или 4G. \n\nПосле видеоролика вам будет предложено ответить на вопросы с несколькими вариантами ответов.\n\nВы сможете ставить видео на паузу, и вам рекомендуется делать заметки по ходу обучения.",
            covidTitle : "COVID-19",
            covidInfo : "На следующей странице вы перейдете к видеоролику с руководством {EP_BRANDNAME_SHORT} касательно COVID-19.\n\nЭто видео является ключевой частью вашего вводного инструктажа, пожалуйста, обратите на него полное внимание. \n\nВы должны будете соблюдать содержание видео в любое время, находясь на рабочем месте.\n\nВместе мы поможем снизить последствия от пандемии COVID-19. \n\nБлагодарим за внимание.",
            minutes : "{duration} минут"
        },
        questionnaire : {
            title : "Контрольные вопросы для проверки усвоения содержания вводного инструктажа:",
            message : "Опросник {EP_BRANDNAME_SHORT} состоит из 15 вопросов, при этом для успешного прохождения инструктажа необходимо получить проходной балл 13.\n\nУ вас будет 2 попытки ответить на вопросы.\n\nПосле двух неудачных попыток вы должны будете повторно просмотреть видеоролик вводного инструктажа компании {EP_BRANDNAME_SHORT}.\n\nПросто отметьте поле с выбранным вами ответом и нажмите кнопку \"Продолжить\".",
            questionNumber : "{position}/{total}",
            resultCongratulations : "ПОЗДРАВЛЯЕМ",
            resultCongratsSubtitle : "вы успешно прошли вводный инструктаж.",
            resultYourScore : "Ваш балл:",
            resultScore : "{correct}/{total}",
            resultFooter : "Пожалуйста, выберите \"Продолжить\", чтобы перейти к видеоролику с руководством по мерам предотвращения заболеваемости COVID-19.",
            resultFailure1 : "К сожалению, вы не набрали проходной балл, необходимый для прохождения данного вводного инструктажа.\n\nТеперь вы будете перенаправлены в начало контрольных вопросов для второй попытки.Если ваша вторая попытка также окажется неудачной, вы должны будете повторно просмотреть видеоролик вводного инструктажа компании {EP_BRANDNAME_SHORT}.\n\nКогда вы будете готовы, выберите \"Продолжить\", чтобы начать отвечать на вопросы.",
            resultFailure2 : "К сожалению, вы не набрали проходной балл, необходимый для прохождения данного вводного инструктажа.\n\nПосле двух неудачных попыток, сейчас вы должны повторно просмотреть видеоролик вводного инструктажа компании {EP_BRANDNAME_SHORT}.\n\nНе забывайте делать заметки и уделите видеоролику все свое внимание.\n\nКогда вы будете готовы, выберите \"Продолжить\", чтобы начать просмотр видеоролика.",
            question1 : "Какой подтверждающий документ вам необходимо предоставить, если вы собираетесь носить пылезащитную/лицевую маску?",
            question1a : "Вводный инструктаж",
            question1b : "Сертификат плотности прилегания к лицу",
            question1c : "Последний сертификат прохождения медицинского освидетельствования ",
            question1d : "Проект производства работ (ППР)",
            question2 : "Если вы используете передвижные подъемные рабочие платформы для выполнения своей задачи, что должно быть в наличии до начала работ?",
            question2a : "Подходящие заграждения (барьеры)",
            question2b : "Четкие предупреждающие знаки",
            question2c : "План эвакуации",
            question2d : "Все из вышеуказанного",
            question3 : "До начала работ, что должно быть выполнено совместно с вашим супервайзером, на ежедневной основе?",
            question3a : "Отчет о ежедневной инспекции оборудования",
            question3b : "Вводный инструктаж на проект",
            question3c : "Пункты буклета оценки рисков «Дай 5»",
            question3d : "Отчет по безопасности и качеству (SQR)",
            question4 : "Что вам необходимо проверить прежде, чем подняться на леса?",
            question4a : "Скорость ветра",
            question4b : "Ваш наряд-допуск",
            question4c : "Что на лесах прикреплена и хорошо читается бирка с датой проверки лесов",
            question4d : "Время следующей учебной пожарной тревоги",
            question5 : "Какие минимальные СИЗ необходимо постоянно носить на этом проекте?",
            question5a : "Каска, защитные брюки, защитная лицевая маска и перчатки",
            question5b : "Светоотражающий жилет / куртка, защитные очки, каска, защитная обувь",
            question5c : "Защитные ботинки со стальным носком и защитной подошвой, каска, светоотражающий жилет / куртка, защитные очки и подходящие перчатки.",
            question5d : "Защитные очки, водонепроницаемая куртка, каска и калоши",
            question6 : "Что нужно делать, если вы заметили некачественное выполнение работ или повреждение строительных изделий / компонентов?",
            question6a : "Заполните сообщение о замечании по безопасности с помощью приложения {EP_BRANDNAME_SHORT} SQR",
            question6b : "Пересмотрите свой ППР",
            question6c : "Выполните пункты буклета оценки рисков «Дай 5»",
            question6d : "Заполните сообщение о замечании по качеству с помощью приложения {EP_BRANDNAME_SHORT} SQR",
            question7 : "Какие из перечисленных ниже разрешений действительны только в течение одного дня?",
            question7a : "Разрешения на работы на высоте, общие и земляные работы",
            question7b : "Разрешения на общие работы, отключение коммуникаций и земляные работы",
            question7c : "Разрешения на огневые, земляные и общие работы",
            question7d : "Разрешения на земляные, огневые работы и отключение коммуникаций",
            question8 : "Что остается одной из самых больших причин смертельных случаев и серьезных травм в строительной отрасли?",
            question8a : "Раздавливание тяжелым оборудованием и механизмами",
            question8b : "Обрушение котлованов",
            question8c : "Падение с высоты",
            question8d : "Поражение электротоком",
            question9 : "Что необходимо обеспечить при работе рядом со световыми проемами?",
            question9a : "Они должны быть четко обозначены и окрашены в желтый цвет для лучшей видимости",
            question9b : "Они должны иметь ограждения или быть закрытыми досками.",
            question9c : "На вас должна быть надета страховочная обвязка",
            question9d : "У вас должен быть наряд-допуск на общие работы",
            question10 : "В каких из перечисленных ниже зон разрешено курить?",
            question10a : "В любом месте в пределах бытовых зон и помещений",
            question10b : "На удалении от газовых баллонов",
            question10c : "В кабинах машин на площадке",
            question10d : "Только в специально обозначенных местах для курения",
            question11 : "Каковы цели {EP_BRANDNAME_SHORT} в области безопасности для этого проекта?",
            question11a : "Без несчастных случаев и травм, с минимальным вредом для окружающей среды",
            question11b : "Как можно меньше несчастных случаев и стараться не наносить вреда окружающей среде",
            question11c : "Без происшествий, подлежащих обязательной регистрации",
            question11d : "Ноль несчастных случаев и инцидентов, нулевой вред для окружающей среды и комфортная рабочая обстановка",
            question12 : "Какие опасности создает отсутствие порядка на рабочих местах?\n",
            question12a : "Скользкие полы",
            question12b : "Поскальзывание, запинание и опасность возникновения пожара",
            question12c : "Нездоровое окружение на рабочем месте",
            question12d : "Опасность возникновения пожара",
            question13 : "Когда необходимо регистрировать прибытие и убытие с площадки?",
            question13a : "В начале и в конце каждого дня",
            question13b : "В начале и в конце каждой недели",
            question13c : "Каждый раз при входе и выходе с площадки",
            question13d : "При срабатывании пожарной ",
            question14 : "Кто должен взять на себя ответственность за обеспечение качества работ и правильного выполнения работ с первого раза?",
            question14a : "Отдел ОТОСБ и качества компании {EP_BRANDNAME_SHORT}",
            question14b : "Менеджер по качеству компании {EP_BRANDNAME_SHORT}",
            question14c : "Каждый на площадке",
            question14d : "Менеджер проекта",
            question15 : "Где находятся аптечки первой помощи, комплекты для обработки ожогов и дефибриллятор?",
            question15a : "Столовая",
            question15b : "Проектный офис {EP_BRANDNAME_SHORT}",
            question15c : "Повсюду в бытовых зонах/помещениях",
            question15d : "В переговорной"
        },
        submission : {
            title : "Подождите пожалуйста",
            message : "Загрузка. Подождите пожалуйста.",
            progress : "{percentage}%"
        },
        finish : {
            title : "Поздравляем, вы успешно прошли вводный инструктаж для площадки компании {EP_BRANDNAME_SHORT}.",
            numberAbove : "Номер вашего инструктажа:",
            numberBelow : "Данный вводный инструктаж действителен на период в 4 месяца. Теперь вам будет отправлено письмо с подтверждением на указанный вами адрес электронной почты.",
            message : "Данный вводный инструктаж действителен на период в 4 месяца.\nТеперь вам будет отправлено письмо с подтверждением на указанный вами адрес электронной почты.\nПожалуйста, храните это письмо в безопасности и держите его под рукой, чтобы показать работникам на площадке {EP_BRANDNAME_SHORT} при первом входе на каждую новую площадку."
        }
    },
    plPL : {
        common : {
            continue : "Kontynuuj",
            edit : "Edytować",
            pleaseWait : "Proszę czekać",
            next : "Następny",
            previous : "Poprzedni",
            finish : "Koniec"
        },
        country : {
            unitedKingdom : "Zjednoczone Królestwo",
            unitedStates : "Stany Zjednoczone Ameryki",
            germany : "Niemcy",
            russia : "Rosja",
            poland : "Polska",
            romania : "Rumunia"
        },
        language : {
            select : "Zmień język",
            english : "język angielski",
            german : "Niemiecki",
            russian : "Rosyjski",
            polish : "Polskie",
            romanian : "rumuński"
        },
        countrySelection : {
            selectCountry : "Wybierz kraj",
            selectLanguage : "Zmień język",
            selectSubtitle : "Ten wybór określi język mówiony filmów wprowadzających i kwestionariusza."
        },
        inductionIntro : {
            title : "Witamy na szkoleniu {EP_BRANDNAME_SHORT} online\nSzkolenie to nie powinno trwać dłużej, niż 1 godzina i ważne będzie przez 4 miesiące na wszystkich placach budowy {EP_BRANDNAME_SHORT} w wybranym kraju.\n\nPrzed rozpoczęciem, proszę się upewnić, że bateria w urządzeniu mobilnym jest wystarczająco naładowana oraz, że zasięg WIFI lub 4G jest silny."
        },
        inductionSignature : {
            gdprTitle : "Zgoda RODO",
            gdprMessage : "Wszystkie dane będą przechowywane na zabezpieczonym serwerze {EP_BRANDNAME_SHORT} i wykorzystywane do celów przeprowadzenia szkolenia we wszystkich aktywnych projektach budowlanych {EP_BRANDNAME_SHORT} w wybranym kraju.\nDane te nie zostaną udostępnione nikomu innemu i na pisemną prośbę skierowaną do {EP_BRANDNAME_SHORT} można je przejrzeć lub zażądać usunięcia.\nWyrażam zgodę na wykorzystanie moich danych w sposób wyżej opisany.",
            inductionTitle : "Deklaracja Szkolenia",
            inductionMessage : "W pełni rozumiem moje obowiązki w zakresie zdrowia i bezpieczeństwa odnoszące się do mnie i do innych, których moja praca może dotyczyć, a także do {EP_BRANDNAME_SHORT}, tak jak jest to opisane we wprowadzeniu ds. bezpieczeństwa {EP_BRANDNAME_SHORT} Ltd.\nZobowiązuje się do przestrzegania wszystkich regulaminów placów budowy, wymaganych pozwoleń, ocen ryzyka i instrukcji wydanych przez mojego pracodawcę lub Głównego Wykonawcę.\nZgadzam się z powyższym oświadczeniem.",
            ramsTitle : "Akceptacja Oświadczenia dotyczącego metody oceny ryzyka",
            ramsMessage : "Ja, niżej podpisany, potwierdzam, że przeczytałem i w pełni zrozumiałem Metody oceny ryzyka i załączone dokumenty\nZgadzam się w całości z opisanymi bezpiecznymi metodami pracy, i w żadnym momencie nie odstąpię od zaproponowanych bezpiecznych metod pracy.",
            agreement : "Imię i nazwisko dużymi literami i zaznaczenie okienka"
        },
        inductionPhoto : {
            title : "Aktualne zdjęcie",
            takePhoto : "Take Photo",
            photoHint : "Proszę o dostarczenie aktualnego zdjęcia.",
            photoNotice : "To zdjęcie będzie użyte przez wyznaczony przez {EP_BRANDNAME_SHORT} personel ochrony do celów identyfikacji."
        },
        inductionUserDetails : {
            title : "Dane do rejestracji",
            fullName : "Imię i nazwisko",
            company : "Nazwa firmy",
            trade : "Branża / pozycja",
            email : "Email",
            emailVerify : "Zweryfikuj adres email",
            emailMismatchError : "Adres email nie pasuje",
            contactNumber : "Numer telefonu",
            homeAddressLine1 : "Adres domowy 1",
            homeAddressLine2 : "Adres domowy 2",
            homeAddressCity : "Miejscowość",
            homeAddressCounty : "Hrabstwo",
            homeAddressPostcode : "Kod pocztowy",
            dateOfBirth : "Data urodzenia",
            age : "Wiek w przypadku osoby poniżej 18 roku życia",
            nextOfKinName : "Dane najbliższego członka rodziny",
            nextOfKinSame : "Same address as operative",
            medicalIntro : "Czy cierpisz na przewlekłe choroby/alergie, które mogą mieć wpływ na pracę lub na które praca może wpłynąć?\n(np. wady wzroku lub słuchu)",
            medicalLabel : "Jeżeli tak, proszę wymienić jakie.",
            medicalOptOut : "Jeżeli nie, proszę zaznaczyć okienko."
        },
        competency : {
            title : "Dowód uprawnień",
            subheading : "Proszę o dostarczenie danych i zdjęć aktualnych kart szkoleniowych\nWybierz „Dodaj“",
            frontOfCard : "Przód karty",
            backOfCard : "Tył karty",
            updatePhoto : "Dodaj zdjęcie",
            cardType : "Rodzaj karty",
            idNumber : "Numer",
            expiryDate : "Data ważności",
            expires : "Data ważności: {date}",
            expiryHint : "(Karta CSCS, użyj ostatniego dnia miesiąca ważności)"
        },
        inductionVideo : {
            introTitle : "Film szkoleniowy",
            introInfo : "Film ten jest kluczową częścią szkolenia – prosimy o uważne oglądanie\nFilm trwa {video_duration}, proszę się upewnić, że bateria w urządzeniu mobilnym jest wystarczająco naładowana oraz, że zasięg WIFI lub 4G jest silny.\nPo filmie zostanie przeprowadzony test wielokrotnego wyboru.\nIstnieje możliwość zatrzymania filmu, w czasie oglądania zalecamy robienie notatek.",
            covidTitle : "COVID-19",
            covidInfo : "Na następnej stronie obejrzysz film instruktarzowy {EP_BRANDNAME_SHORT} dotyczący COVID-19.\nFilm ten jest częścią twojego szkolenia – obejrzyj go w skupieniu.\nBędziesz zobowiązany do postępowania zgodnie z zaleceniami tego filmu, zawsze podczas pobytu na miejscu budowy.\nRazem możemy zmniejszyć wpływ COVID-19.\nDziękujemy.",
            minutes : "{duration} minut"
        },
        questionnaire : {
            title : "Test szkoleniowy",
            message : "Kwestionariusz {EP_BRANDNAME_SHORT} składa się z 15 pytań. Aby go zaliczyć, trzeba poprawnie odpowiedzieć na co najmniej 13 pytań\nJest możliwość dwóch prób podejścia do testu.\nPo dwóch nieudanych próbach trzeba ponownie obejrzeć film szkoleniowy {EP_BRANDNAME_SHORT}.\nTrzeba po prostu zaznaczyć właściwą odpowiedź i nacisnąć kontynuuj.",
            questionNumber : "{position}/{total}",
            resultCongratulations : "Gratulacje",
            resultCongratsSubtitle : "Test szkoleniowy został zaliczony",
            resultYourScore : "Twój wynik to:",
            resultScore : "{correct}/{total}",
            resultFooter : "Wybierz kontynuuj, żeby zobaczyć film instruktażowy dotyczący COVID – 19.",
            resultFailure1 : "Niestety, ilość punktów wymagana do zaliczenia testu nie została osiągnięta.\nNastąpi teraz przekierowanie na początek testu, aby spróbować jeszcze raz.\nW przypadku ponownego niezaliczenia testu, trzeba ponownie obejrzeć film szkoleniowy.\nW dogodnym momencie, wybierz kontynuuj, aby rozpocząć test.",
            resultFailure2 : "Niestety, ilość punktów wymagana do zaliczenia testu nie została osiągnięta.\nPo dwóch nieudanych próbach, teraz trzeba jeszcze raz obejrzeć film szkoleniowy {EP_BRANDNAME_SHORT}.\nPamiętaj o robieniu notatek i oglądaj film w skupieniu.\nW dogodnym momencie, wybierz kontynuuj, aby rozpocząć test.",
            question1 : "Jaki dowód musisz okazać, kiedy zamierzasz nosić maskę?",
            question1a : "Szkolenia",
            question1b : "Certyfikat Dopasowania Maski",
            question1c : "Ostatnie badania medyczne",
            question1d : "Oświadczenie o metodzie",
            question2 : "Podczas używania MEWPs (Podestów Ruchomych Przejezdnych) co musi być na miejscu przed rozpoczęciem prac?",
            question2a : "Odpowiednie barierki",
            question2b : "Czytelne znaki ostrzegawcze",
            question2c : "Plan ewakuacji",
            question2d : "Wszystkie z wymienionych rzeczy",
            question3 : "Przed rozpoczęciem prac, co musi być codzienne wypełnione z Przełożonym?",
            question3a : "Codzienny raport z inspekcji zakładu",
            question3b : "Szkolenie projektowe",
            question3c : "Formularz oceny ryzyka metodą 5 kroków",
            question3d : "Raport Jakości Bezpieczeństwa (SQR)",
            question4 : "Co musi być sprawdzone przed wejściem na rusztowanie?",
            question4a : "Prędkość wiatru",
            question4b : "Pozwolenie na prace",
            question4c : "Umieszczona w widocznym miejscu etykieta kontroli rusztowania",
            question4d : "Termin najbliższej próbnej ewakuacji przeciwpożarowej.",
            question5 : "Minimalne środki ochrony osobistej, noszone na tej budowie, przez cały czas to?",
            question5a : "Kask, spodnie ochronne, przyłbica i rękawice",
            question5b : "Kamizelka/kurtka odblaskowa, okulary ochronne, kask, buty ochronne",
            question5c : "Buty robocze ze stalowym podnoskiem ochronnym i stalową wkładką antyprzebiciową, kask, kamizelka/kurtka odblaskowa, okulary ochronne i odpowiednie rękawice",
            question5d : "Gogle ochronne, kurtka wodoodporna, kask i kalosze",
            question6 : "Co musisz zrobić, jeżeli zauważysz złą jakość wykonania lub uszkodzenia w materiałach budowlanych/komponentach?",
            question6a : "Wypełnić Raport Obserwacji Bezpieczeństwa za pomocą aplikacji {EP_BRANDNAME_SHORT} SQR",
            question6b : "Zweryfikować metody oceny",
            question6c : "Wypełnić formularz oceny ryzyka metodą 5 kroków",
            question6d : "Wypełnić Raport Obserwacji Jakości za pomocą aplikacji {EP_BRANDNAME_SHORT} SQR.",
            question7 : "Które z poniższych zezwoleń są ważne tylko przez jeden dzień?",
            question7a : "Pozwolenia na prace na wysokościach, ogólne i na wykopy",
            question7b : "Pozwolenia ogólne, na wyłączenie serwisowe i na wykopy",
            question7c : "Pozwolenia na prace pożarowo niebezpiecznie, Na wykopy i Ogólne",
            question7d : "Pozwolenie na wykopy, na wyłączenie serwisowe i na prace pożarowo niebezpieczne ",
            question8 : "Co pozostaje najczęstszym powodem zgonów i poważnych obrażeń w przemyśle budowlanym?",
            question8a : "Przygniecenie ciężkimi maszynami i sprzętem",
            question8b : "Osunięcie wykopu",
            question8c : "Upadek z wysokości",
            question8d : "Porażenie prądem",
            question9 : "O czym trzeba pamiętać pracując przy świetlikach?",
            question9a : "O czytelnym oznaczeniu pomalowanym na żółto dla lepszej widoczności",
            question9b : "O ogrodzeniu ich barierkami dookoła lub zakryciu deskami",
            question9c : "O noszeniu uprzęży",
            question9d : "O posiadaniu ogólnego pozwolenia na pracę",
            question10 : "W których, z wymienionych miejsc, dozwolone jest palenie papierosów?",
            question10a : "Wszędzie poza przestrzeniami socjalnymi",
            question10b : "Z dala od pojemników z gazem",
            question10c : "W kabinach sterowniczych na terenie budowy",
            question10d : "Tylko w miejscach wyznaczonych do palenia",
            question11 : "Jakie są Cele Bezpieczeństwa {EP_BRANDNAME_SHORT} w tym Projekcie?",
            question11a : "Praca bez wypadków i urazów oraz niska szkodliwość dla środowiska",
            question11b : "Możliwie jak najmniej wypadków i próbujemy nie szkodzić środowisku",
            question11c : "Żadnych wypadków RIDDOR",
            question11d : "Zero wypadków i incydentów, zero szkodliwości dla środowiska oraz przyjazne środowisko pracy",
            question12 : "Jakie zagrożenia stwarza niedokładne sprzątanie?\n",
            question12a : "Śliskie podłogi.",
            question12b : "Poślizgi, Upadki i Zagrożenia Pożarowe",
            question12c : "Niezdrowe środowisko pracy",
            question12d : "Zagrożenia pożarowe",
            question13 : "Kiedy musisz się zarejestrować na placu bodowy i z niego wyrejestrować?",
            question13a : "Na początku i końcu dnia",
            question13b : "Na początku i końcu tygodnia",
            question13c : "Za każdym razem, kiedy wchodzisz na teren placu bodowy i wychodzisz z niego.",
            question13d : "Kiedy włączy się alarm pożarowy. ",
            question14 : "Kto powinien wykazać inicjatywę i odpowiedzialność, aby zapewnić Jakość prac i poprawne wykonanie rzeczy przy pierwszym podejściu?",
            question14a : "Wydział HSQE {EP_BRANDNAME_SHORT}",
            question14b : "Manager ds. jakości {EP_BRANDNAME_SHORT}",
            question14c : "Wszyscy na miejscu budowy",
            question14d : "Manager Projektu",
            question15 : "Zestawy Pierwszej Pomocy, Zestawy Oparzeniowe i Defibrylator są umieszczone:",
            question15a : "Na stołówce",
            question15b : "W biurze projektowym {EP_BRANDNAME_SHORT}",
            question15c : "Na obszarach socjalnych",
            question15d : "W pokoju spotkań"
        },
        submission : {
            title : "Proszę czekać",
            message : "Twoje szkolenie jest przesyłane. Proszę czekać.",
            progress : "{percentage}%"
        },
        finish : {
            title : "Gratulacje, właśnie zakończyłeś z powodzeniem szkolenie {EP_BRANDNAME_SHORT}.",
            numberAbove : "Twój numer szkolenia to",
            numberBelow : "Szkolenie jest ważne przez 4 miesiące",
            message : "Na przekazany nam adres otrzymasz wiadomość e-mail z potwierdzeniem\nPamiętaj, aby zachować tą wiadomość i mieć ją przy sobie w celu okazania go personelowi {EP_BRANDNAME_SHORT}, kiedy po raz pierwszy odwiedzasz nowy plac budowy."
        }
    },
    roRO : {
        common : {
            continue : "Continuă",
            edit : "Editați",
            pleaseWait : "Va rugam asteptati",
            next : "Următorul",
            previous : "Anterior",
            finish : "Sfârșit"
        },
        country : {
            unitedKingdom : "Regatul Unit",
            unitedStates : "Statele Unite ale Americii",
            germany : "Germania",
            russia : "Rusia",
            poland : "Polonia",
            romania : "România"
        },
        language : {
            select : "Schimbați limba",
            english : "Engleză",
            german : "limba germana",
            russian : "Rusă",
            polish : "Lustrui",
            romanian : "Română"
        },
        countrySelection : {
            selectCountry : "Alege țara",
            selectLanguage : "Selectați o limbă",
            selectSubtitle : "Această selecție va determina limba vorbită a videoclipurilor de inducție și a chestionarului."
        },
        inductionIntro : {
            title : "Bine ai venit la instructajul online {EP_BRANDNAME_SHORT}\nAcest instructaj nu ar trebui să dureze mai mult de 1 oră pentru a fi finalizat și va fi valabil timp de 4 luni pe toate locațiile {EP_BRANDNAME_SHORT} din țara selectată.\nÎnainte de a continua, vă rugăm să vă asigurați că dispozitivul dvs. mobil are baterie suficientă și e conectat la o rețea WIFI sau 4G."
        },
        inductionSignature : {
            gdprTitle : "Consimțământul GDPR",
            gdprMessage : "Toate datele furnizate vor fi păstrate pe serverul securizat {EP_BRANDNAME_SHORT} în scopul gestionării instructajelor în cadrul proiectelor {EP_BRANDNAME_SHORT} din țara selectată.\nDatele dvs. cu caracter personal nu vor fi transmise terților și veți putea revizui sau șterge datele dvs. în urma unei cereri scrise adresate {EP_BRANDNAME_SHORT}.\nSunt de acord ca {EP_BRANDNAME_SHORT} să-mi folosească datele în modul descris mai sus.",
            inductionTitle : "Declarație",
            inductionMessage : "Înțeleg pe deplin responsabilitățile mele în ceea ce privește sănătatea, siguranța și bunăstarea mea și a celor din jur din perspectiva muncii mele cât și față de {EP_BRANDNAME_SHORT}, așa cum este prezentat în instructajul {EP_BRANDNAME_SHORT}.\nVoi respecta toate regulile site-ului, cerințele de autorizare, evaluarea riscurilor relevante și declarațiile emise de angajatorul meu sau de contractantul principal\nSunt de acord cu cele de mai sus",
            ramsTitle : "Accept RAMS",
            ramsMessage : "Prin semnătură confirm că am citit și înțeles declarația, evaluările riscurilor și documentația aferentă.\nSunt de acord să respect condițiile de securitate în muncă, așa cum sunt detaliate în întregime și nu mă voi abate în niciun moment de la condițiile de securitate în muncă propuse.",
            agreement : "Introdu numele și selectează opțiunea"
        },
        inductionPhoto : {
            title : "Fotografie",
            takePhoto : "Adaugă",
            photoHint : "Vă rugăm să furnizați o fotografie recentă cu dvs.",
            photoNotice : "Această fotografie va fi utilizată de personalul de securitate desemnat de {EP_BRANDNAME_SHORT} în procesul de  identificare."
        },
        inductionUserDetails : {
            title : "Înregistrați-vă datele",
            fullName : "Numele complet",
            company : "Companie",
            trade : "Domeniu/ Ocupație",
            email : "Email",
            emailVerify : "verifica adresa de email",
            emailMismatchError : "Adresele de Email nu corespund",
            contactNumber : "Număr de contact",
            homeAddressLine1 : "Adresa de domiciliu 1",
            homeAddressLine2 : "Adresa de domiciliu 2",
            homeAddressCity : "Oraș",
            homeAddressCounty : "Județul",
            homeAddressPostcode : "Cod poștal",
            dateOfBirth : "Dana nașterii",
            age : "Vârsta în cazul în care aveți sub 18 ani",
            nextOfKinName : "Rude",
            nextOfKinSame : "Aceeași adresă ca și operatorul",
            medicalIntro : "Suferiți de afecțiuni / alergii care vă pot afecta munca?\n(de exemplu, deficiențe de vedere sau de auz)",
            medicalLabel : "Dacă răspunsul este da, vă rog să detaliați mai jos",
            medicalOptOut : "Dacă răspunsul este nu, bifați caseta de mai jos"
        },
        competency : {
            title : "Dovada competențelor",
            subheading : "Vă rugăm să furnizați detalii și o fotografie cu certificatele de competență valide.\nSelectează adaugă",
            frontOfCard : "Față",
            backOfCard : "Spate",
            updatePhoto : "Adaugă fotografie",
            cardType : "Tipul de certificat",
            idNumber : "Număr de identificare",
            expiryDate : "Data de expirare",
            expires : "Data de expirare : {date}",
            expiryHint : "(Carduri CSCS, utilizați ultima zi a lunii de expirare)"
        },
        inductionVideo : {
            introTitle : "Video",
            introInfo : "Acest video reprezintă o parte esențială a trainingului dvs., vă rugăm urmăriți cu atenție.\nAcest video va dura {video_duration}, vă rugăm să vă asigurați că dispozitivul dvs. mobil are suficientă bateriei și este conectat la o rețea WIFI sau 4G cu semnal puternic.\nla finalul acestui video va exista un chestionar cu variante de răspuns multiple.\nPuteți  întrerupe videoclipul oricând. Este recomandat ca pe parcursul acestui video să luați notițe.",
            covidTitle : "COVID-19",
            covidInfo : "În pagina următoare veți fi direcționați către {EP_BRANDNAME_SHORT}\nVideo {EP_BRANDNAME_SHORT} privind COVID-19.\nAcest videoclip reprezintă o parte esențială a instructajului dvs., vă rugăm să urmăriți cu atenție.\nPe toată durata șederii dumneavoastră vi se va cere să respectați solicitările din acest video.\nÎmpreună, putem ajuta la reducerea impactului COVID-19.\nMulțumesc.",
            minutes : "{duration} de minute"
        },
        questionnaire : {
            title : "Chestionar",
            message : "Chestionarul {EP_BRANDNAME_SHORT} conține 15 întrebări, pentru a avansa trebuie să obțineți un punctaj minim de 13 puncte.\nVeți avea 2 încercări de a completa chestionarul.\nDupă 2 încercări eșuate, vi se va cere să urmăriți din nou videoclipul de instructaj {EP_BRANDNAME_SHORT}.\nSelectați răspunsului ales și alegeți opțiunea continuare.",
            questionNumber : "{position}/{total}",
            resultCongratulations : "FELICITĂRI",
            resultCongratsSubtitle : "ați trecut de chestionar",
            resultYourScore : "Punctajul dvs este",
            resultScore : "{correct}/{total}",
            resultFooter : "Vă rugăm să continuați să vizionați videoclipul de îndrumare COVID-19",
            resultFailure1 : "Din păcate, nu ați obținut punctajul necesar pentru a finaliza acest instructaj.\nÎn cazul în care nu reușiți a doua încercare, vi se va cere să urmăriți din nou videoclipul de instructaj {EP_BRANDNAME_SHORT}.\nCând sunteți pregătiți, vă rugăm să apăsați continuare pentru a începe chestionarul.",
            resultFailure2 : "Din păcate, nu ați obținut punctajul necesar pentru a finaliza acest instructaj .\nDupă 2 încercări nereușite, vi se cere să urmăriți din nou videoclipul de instructaj {EP_BRANDNAME_SHORT}.\nNu uitați să luați notițe și să urmăriți cu atenție videoclipul.\nCând sunteți pregătiți, selectați opțiunea continuare pentru a începe videoclipul.",
            question1 : "Dacă urmează să folosiți o mască de praf sau de protecție ce document de certificare aveți nevoie?",
            question1a : "Instructajul",
            question1b : "Certificatul de protecție a feței",
            question1c : "Ultimul certificat de evaluare medicală",
            question1d : "O declarație privind securitatea în muncă",
            question2 : "Dacă utilizați platforme elevatoare construcții, înaintea începerii lucrărilor, trebuie să vă asigurați că în șantier există?",
            question2a : "Gard de protecție",
            question2b : "Indicatoare de avertizare",
            question2c : "Un plan de intervenție",
            question2d : "Toate cele de mai sus",
            question3 : "Înainte de începerea lucrărilor, ce document trebuie completat zilnic de supervizorul dumneavoastră?",
            question3a : "Un raport zilnic de inspecție a instalației",
            question3b : "Descrierea proiectului",
            question3c : "Broșura de evaluare a riscurilor ",
            question3d : "Un raport privind calitatea siguranței ",
            question4 : "Înainte de a intra pe o structură de schele, ce ar trebui să verificați?",
            question4a : "Viteza vântului",
            question4b : "Permisul dvs. de lucru",
            question4c : "Că este afișată clar o etichetă validă pentru inspecția schelelor",
            question4d : "Ora următorului exercițiu de incendiu",
            question5 : "Echipamentul de protecție minim care trebuie purtat în orice moment este?",
            question5a : "Cască, pantaloni de protecție, vizor și mănuși",
            question5b : "Vesta/sacou fluorescente, ochelari de protecție, cască, pantofi de protecție",
            question5c : "Ghete de siguranță din oțel, cască de protecție, vesta/sacou fluorescente, ochelari de protecție și mănuși adecvate",
            question5d : "Ochelari de protecție, jachetă impermeabilă, cască de protecție și cizme de cauciuc",
            question6 : "Ce trebuie să reacționați dacă observați o că o lucrare nu este făcută la standarde sau că anumite componente și materiale de construcții sunt de slabă calitate?",
            question6a : "Completați un raport de observație privind securitatea utilizând aplicația {EP_BRANDNAME_SHORT} SQR",
            question6b : "Reexaminați declarația privind securitatea în muncă",
            question6c : "Completați o broșură de evaluare a riscurilor",
            question6d : "Completați un raport de observație privind calitatea utilizând aplicația {EP_BRANDNAME_SHORT} SQR",
            question7 : "Care dintre următoarele permise sunt valabile doar pentru o singură zi?",
            question7a : "Permisele generale, excavare și pentru lucrări la înălțime",
            question7b : "Permisele generala, pentru izolare și excavare ",
            question7c : "Permisele generale și pentru excavare și cele de muncă la cald",
            question7d : "Permise pentru excavare, izolare și permisele pentru munca la cald",
            question8 : "Care este principala cauză a deceselor și accidentelor pe șantierele de construcții?",
            question8a : "Strivirea din cauza instalațiilor grele și echipamentelor ",
            question8b : "Prăbușirea săpăturilor",
            question8c : "Căderile de la înălțime",
            question8d : "Electrocutarea",
            question9 : "Dacă lucrați în preajma luminatoarelor, trebuie să vă asigurați că -",
            question9a : "Sunt semnalizate și vopsite în galben pentru a fi ușor vizibile ",
            question9b : "Sunt dispuse bare de protecție în jurul lor sau sunt acoperite cu materiale rezistente",
            question9c : "Purtați un ham ",
            question9d : "Aveți un permis general de lucru",
            question10 : "În care dintre următoarele zone este permis fumatul?",
            question10a : "Oriunde în zona unde se servește masa",
            question10b : "La o distanță considerabilă față de buteliile de gaz",
            question10c : "În cabinele utilajelor de pe șantier",
            question10d : "Doar în zonele destinate fumătorilor",
            question11 : "Care sunt obiectivele de siguranță ale {EP_BRANDNAME_SHORT} pentru acest proiect?",
            question11a : "Fără accidente și vătămări corporale, cu daune scăzute mediului",
            question11b : "Cât mai puține accidente posibil și încercați să nu afectați mediului",
            question11c : "Fără accidente raportate",
            question11d : "Zero accidente și incidente, zero daune mediului și un mediu de lucru plăcut",
            question12 : "Ce efecte pot fi produse printr-o curățenie precară?\n",
            question12a : "Podea alunecoasă",
            question12b : "Alunecări, căderi și pericole de incendiu ",
            question12c : "Un loc de muncă nesănătos",
            question12d : "Pericole de incendiu",
            question13 : "Când trebuie să vă înregistrați intrarea și ieșirea din șantier?",
            question13a : "La începutul și la sfârșitul zilei",
            question13b : "La începutul și sfârșitul săptămânii",
            question13c : "De fiecare dată când intrați și ieșiți din șantier",
            question13d : "Când pornește alarmă de incendiu",
            question14 : "Cine este responsabil că lucrările de pe șantier sunt de calitate?",
            question14a : "Departamentul {EP_BRANDNAME_SHORT} de securitate în câmpul muncii",
            question14b : "Managerul de calitate {EP_BRANDNAME_SHORT}",
            question14c : "Toată lumea de pe șantier",
            question14d : "Managerul de proiect",
            question15 : "Trusele de prim ajutor, trusele pentru arsuri și defibrilatorul se află în?",
            question15a : "Vestiar",
            question15b : "Biroul de proiect {EP_BRANDNAME_SHORT}",
            question15c : "În zona băilor, a vestiarelor și zonelor de luat masa",
            question15d : "Sala de ședințe"
        },
        submission : {
            title : "Va rugam asteptati",
            message : "Încărcare. Va rugam asteptati.",
            progress : "{percentage}%"
        },
        finish : {
            title : "Felicitări, ați finalizat cu succes instructajul {EP_BRANDNAME_SHORT}.",
            numberAbove : "Numărul dvs. de intrare este",
            numberBelow : "Acest instructaj este valabilă 4 luni.",
            message : "Veți primi acum un e-mail de confirmare la adresa de e-mail pe care ați furnizat-o.\nVă rugăm să păstrați acest e-mail în siguranță și disponibil pentru a fi verificat de către echipa {EP_BRANDNAME_SHORT}."
        }
    }
}